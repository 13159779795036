import styled from "styled-components";
import { Box } from "@mui/material";
import { ReactComponent as MiniLogoImg } from "../../assets/images/svg/mini-logo-tremium.svg";
import Typography from "@mui/material/Typography";

export const FooterContainer = styled(Box)`
  border-top: 1px solid ${(props) => props?.theme?.colors?.textUncompletedStep};
  width: 100%;
  padding-top: 15px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors?.footerBackground};
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin-top: 3px;
      margin-bottom: 0px;
      padding-top: 2px;
      padding-bottom: 2px;
      height: fit-content;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 3px;
    margin-bottom: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: fit-content;
  }
`;
export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  padding: 2px;
  color: ${(props) => props?.theme?.colors?.darkBlue};

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const MiniLogo = styled(MiniLogoImg)`
  margin-bottom: 5px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin: 0px;
    height: 18px;
    width: 30px;
  }
`;
