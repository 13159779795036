import { Typography } from "@mui/material";
import { ReactComponent as ProfilePicture } from "../../assets/images/svg/super-admin-profile-picture.svg";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0px 10px 0px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin: 16px 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin: 16px 0px;
    }
  }
`;

export const UpperContainterWithProfilePicture = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      flex-direction: column-reverse;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: column-reverse;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column-reverse;
      margin-bottom: 0px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      width: 100%;
      flex-direction: row;
      justify-content: end;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      height: 32px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      flex-direction: row;
      height: 32px;
      justify-content: space-between;
    }
  }
`;

export const ProfileWrapper = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 61px;
  justify-content: end;
  margin-left: ${({ isCollapsed }) =>
    isCollapsed ? "0px" : "calc(100% - 61px)"};
  z-index: 10;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      justify-content: end;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      justify-content: end;
    }
  }
`;

const StyledImage = styled.img`
  display: flex;
  align-self: center;
  width: 40px;
  height: 40px;
`;

export const ProfilePictureContainer = styled(StyledImage).attrs({
  as: ProfilePicture,
})``;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 5px;
  width: 100%;
  margin: 10px 0px 0px 0px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin-top: 10px;
      flex-direction: row;
      gap: 4px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-top: 10px;
      flex-direction: row;
      gap: 4px;
    }
  }
`;

export const LabelIconContainer = styled.div`
  align-self: center;
  justify-self: center;
  padding-right: 5px;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.4px;
  font-family: "Baloo 2" !important;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 28px;
      line-height: 24px;
    }
  }
  
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 20px;
      line-height: 20px;
      margin-top: 4px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 20px;
      line-height: 20px;
      margin-top: 4px;
    }
  }
`;
