import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const Modal = styled.div`
  width: fit-content;    
  box-sizing: border-box;
  overflow: hidden;   
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-bottom: none;
  position: relative;
  background-color: ${(props) => props?.theme?.colors?.white};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 25px;
  padding-right: 60px;
  width: 100%;
  height: fit-content;
  overflow: auto; 
`;
