import { useEffect, useMemo, useState } from "react";
import deviceConstants from "../constants/deviceConstants";

function getScreenWidth() {
  return document.documentElement.clientWidth;
}

function getScreenHeight() {
  return document.documentElement.clientHeight;
}

const useDevice = () => {
  const [device, setDevice] = useState(
    window.innerWidth < 600
      ? deviceConstants.MOBILE
      : window.innerWidth < 900
      ? deviceConstants.TABLET
      : deviceConstants.DESKTOP
  );

  const isMobile = useMemo(() => {
    return device === deviceConstants.MOBILE;
  }, [device]);

  const isTablet = useMemo(() => {
    return device === deviceConstants.TABLET;
  }, [device]);

  const isDesktop = useMemo(() => {
    return device === deviceConstants.DESKTOP;
  }, [device]);

  useEffect(() => {
    const resize = () => {
      let width = getScreenWidth();
      let height = getScreenHeight();
      if (width <= 600 || (height <= 600 && width <= 980)) {
        setDevice(deviceConstants.MOBILE);
      } else if (width <= 1024) {
        setDevice(deviceConstants.TABLET);
      } else {
        setDevice(deviceConstants.DESKTOP);
      }
    };
    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);
  return { device, isMobile, isTablet, isDesktop };
};
export default useDevice;
