import React from "react"
import PropTypes from "prop-types";
import { SendNewCodeContainer } from "./SendNewCodeButton.styled"
import { useTranslation } from "react-i18next";

export const SendNewCodeButton = (props) => {
    const { t } = useTranslation();
    return (
        <SendNewCodeContainer
            onClick={props.onClick}
            colorPalet={props.colorPalet}
            value={t("code.sendNewCode")}
            height={"26px"}
        >
        </SendNewCodeContainer>
    )
}

SendNewCodeButton.propTypes = {
  onClick: PropTypes.func,
  colorPalet: PropTypes.string,
};

export default SendNewCodeButton;