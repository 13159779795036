import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

export const WarningMessage = styled.div`
  color: ${({ theme }) => theme?.colors?.notificationRed};
  font-size: 14px;
  margin-top: 8px;
`;

export const StyledEditor = styled.div`
  border: 1px solid ${({ theme }) => theme?.colors?.textUncompletedStep};
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme?.colors?.white};

  .ql-toolbar {
    display: flex;
    border: none;
    background: ${({ theme }) => theme?.colors?.richTextEditorBackground};
  }

  .ql-toolbar .ql-formats {
    display: flex;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme?.colors?.textUncompletedStep};
    padding-left: 10px;
  }

  .ql-toolbar .ql-formats:first-of-type {
    border-left: none;
    padding-left: 0;
  }

  .ql-container {
    border: none;
    min-height: 200px;
    padding: 8px;
    background: ${({ theme }) => theme?.colors?.white};
  }

  .ql-editor {
    font-family: ${(props) => props?.font || "sans-serif"} !important;
  }
`;
