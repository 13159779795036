import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthContent from "components/AuthContent/AuthContent";
import { useTranslation } from "react-i18next";
import useDevice from "hooks/useDevice";

const RegisterPage = (props) => {
  const { t } = useTranslation();
  const title = t(`register.progress.title.${props.type}`);
  const { isMobile } = useDevice();

  const getStepLabel = (stepIndex, isMobile) => {
    const stepsLabel = isMobile ? "mobileSteps" : "steps";
    return t(`register.progress.${props.type}.${stepsLabel}.${stepIndex}`);
  };

  const [steps, setSteps] = useState([
    { stepNumber: 0, label: getStepLabel(0, isMobile), titleLabel: getStepLabel(0, false), completed: true },
    { stepNumber: 1, label: getStepLabel(1, isMobile), titleLabel: getStepLabel(1, false), completed: false },
    { stepNumber: 2, label: getStepLabel(2, isMobile), titleLabel: getStepLabel(2, false), completed: false },
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      label: getStepLabel(index, isMobile),
    }));
    setSteps(updatedSteps);
  }, [isMobile, t, props.type]);

  const onClickNextPageBtn = () => {
    if (currentStep < steps.length - 1) {
      const updatedSteps = [...steps];
      updatedSteps[currentStep + 1].completed = true;
      setSteps(updatedSteps);
      setCurrentStep(currentStep + 1);
    }
  };

  const onClickBackBtn = () => {
    if (currentStep > 0) {
      const updatedSteps = [...steps];
      updatedSteps[currentStep].completed = false;
      setSteps(updatedSteps);
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <AuthContent
      type={props.type}
      title={title}
      steps={steps}
      label={steps[currentStep].label}
      currentStep={currentStep}
      onClickNextPageBtn={onClickNextPageBtn}
      onClickBackBtn={onClickBackBtn}
    />
  );
};

RegisterPage.propTypes = {
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
};

export default RegisterPage;
