import React from "react";
import PropTypes from "prop-types";
import {
  FooterContainer,
  LeftSection,
  RightSection,
  StyledTypography,
  StyledSelect,
  StyledPagination,
  SelectInputStyled,
} from "./TableFooterPagination.styled";

import { MenuItem } from "@mui/material";

import PreviousTablePageButton from "components/SuperAdmin/Buttons/PreviousTablePageButton/PreviousTablePageButton";
import NextTablePageButton from "components/SuperAdmin/Buttons/NextTablePageButton/NextTablePageButton";
import { TEXTVARIANT } from "constants/textConstants";
import { SIZE, VARIANT } from "constants/buttonConstants";

const TableFooterControls = (props) => {
  return (
    <FooterContainer>
      <LeftSection>
        <StyledTypography variant={TEXTVARIANT.BODY2}>
          {props.t("superAdmin.table.numberOfShowingResultsBeforeSelect")}
        </StyledTypography>
        <StyledSelect variant={VARIANT.OUTLINED} size="small">
          <SelectInputStyled
            value={props.rowsPerPage}
            onChange={props.handleRowsPerPageChange}
            defaultValue={props?.startingNumberOfItemsPerPage}
            sx={{
              padding: {
                xs: "0px",
              },
            }}
          >
            <MenuItem value={props?.startingNumberOfItemsPerPage}>
              {props?.startingNumberOfItemsPerPage}
            </MenuItem>
            <MenuItem
              value={
                props?.startingNumberOfItemsPerPage +
                props?.incrementNumberOfItemsPerPage
              }
            >
              {props?.startingNumberOfItemsPerPage +
                props?.incrementNumberOfItemsPerPage}
            </MenuItem>
            <MenuItem
              value={
                props?.startingNumberOfItemsPerPage +
                props?.incrementNumberOfItemsPerPage * 2
              }
            >
              {props?.startingNumberOfItemsPerPage +
                props?.incrementNumberOfItemsPerPage * 2}
            </MenuItem>
          </SelectInputStyled>
        </StyledSelect>
        <StyledTypography variant={TEXTVARIANT.BODY2}>
          {props.t("superAdmin.table.numberOfShowingResultsAfterSelect", {
            total: props.totalRows,
          })}
        </StyledTypography>
      </LeftSection>

      <RightSection>
        <PreviousTablePageButton
          disabled={props.page === 1}
          onClick={() => props.handlePageChange(props.page - 1)}
        />
        <StyledPagination
          count={props.totalPages}
          page={props.page}
          onChange={(event, value) => props.handlePageChange(value)}
          shape="rounded"
          size={SIZE.SMALL}
          hideNextButton
          hidePrevButton
          siblingCount={0}
        />
        <NextTablePageButton
          onClick={() => props.handlePageChange(props.page + 1)}
          disabled={props.page >= props.totalPages}
        />
      </RightSection>
    </FooterContainer>
  );
};

TableFooterControls.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRows: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  t: PropTypes.any,
  startingNumberOfItemsPerPage: PropTypes.number,
  incrementNumberOfItemsPerPage: PropTypes.number,
};

TableFooterControls.defaultProps = {
  startingNumberOfItemsPerPage: 10,
  incrementNumberOfItemsPerPage: 5,
};

export default TableFooterControls;
