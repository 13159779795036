import styled from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as QuestionMarkIcon } from "assets/images/svg/question-mark-icon.svg";

export const Modal = styled.div`
  width: 460px;
  max-height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: 364px;
  overflow: auto;
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  align-items: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px;
  width: 100%;
  height: 82px;
  padding: 25px 30px;
`;

export const ActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-top: 10px;
`;

export const UpperContainerWrapper = styled(UpperContainer)`
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const QuestionMarkIconStyled = styled(QuestionMarkIcon)`
  height: 56px;
  width: 56px;
`;

export const CancelButton = styled(CancelActionButton)`
  border-color: ${(props) => props?.theme?.colors?.text3};
  font-size: 14px;
`;

export const NoButton = styled(ActionButton)`
  font-size: 14px;
  background-color: ${(props) => props?.theme?.colors?.errorColor};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.errorColorHover};
  }
`;

export const YesButton = styled(ActionButton)`
  font-size: 14px;
  background-color: ${(props) => props?.theme?.colors?.statusActiveButton};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.statusActiveButton};
  }
`;

export const LowerContainerWrapper = styled(LowerContainer)`
  flex-direction: row;
  gap: 10px;
  height: 97px;
  padding: 10px;
  align-items: center;
`;

export const TitleStyled = styled(Title)`
  text-align: center;
`;
