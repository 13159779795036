import * as Yup from "yup";

export default Yup.object().shape({
  startDateUtc: Yup.string()
    .required("register.inputRequired")
    .test(
      "is-valid-date",
      "common.selectTimeRequired",
      (value) => {
        if (!value) return false;
        const parsedDate = new Date(
          value.replace(
            /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
            "$2/$1/$3 $4:$5"
          )
        );
        return !isNaN(parsedDate.getTime());
      }
    ),
  endDateUtc: Yup.string()
    .required("register.inputRequired")
    .test(
      "is-valid-date",
      "common.selectTimeRequired",
      (value) => {
        if (!value) return false;
        const parsedDate = new Date(
          value.replace(
            /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
            "$2/$1/$3 $4:$5"
          )
        );
        return !isNaN(parsedDate.getTime());
      }
    )
    .test(
      "is-after-start-date",
      "calendar.startAndEndNotGood",
      function (value) {
        const { startDateUtc } = this.parent;
        if (!value || !startDateUtc) return false;
        const start = new Date(
          startDateUtc.replace(
            /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
            "$2/$1/$3 $4:$5"
          )
        );
        const end = new Date(
          value.replace(
            /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/,
            "$2/$1/$3 $4:$5"
          )
        );
        return end > start;
      }
    ),
  resourceIds: Yup.array().min(1, "register.inputRequired"),
});
