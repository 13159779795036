import { Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";

export const AvailableSlotsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 16px 24px;
`;

export const LabelAndCalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChooseTimeLabel = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SwitchButtonLabel = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const ChoosePeriodContainer = styled.div`
  display: flex;
  gap: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8.52px;
    }
  }
`;

export const LeftArrowStyled = styled(LeftArrow)`
  cursor: ${(props) => (props?.disabled ? "default" : "pointer")};
`;

export const RightArrowStyled = styled(RightArrow)`
  cursor: pointer;
`;

export const PeriodText = styled(Typography)`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
  align-items: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 13.63px;
      line-height: 20.44px;
    }
  }
`;

export const PeriodLabel = styled(PeriodText)`
  color: ${(props) => props?.theme?.colors?.leftAuthContentBackground};
`;

export const DatesContainer = styled.div`
  display: flex;
  gap: 10px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 3px;
    }
  }
`;

export const AvailableSlotsTableContainer = styled.div`
  width: 100%;
  height: 100%;
`;
