import Button from "components/Button/Button";
import styled from "styled-components";
import { ReactComponent as DownArrowIcon } from "assets/images/svg/down-arrow.svg";
import { ReactComponent as FilterIcon } from "assets/images/svg/filter-icon.svg";

export const FilterButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 6px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  min-height: 33px;
  color: ${(props) => props?.theme?.colors?.black};
  background-color: ${(props) => props?.theme?.colors?.white};

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
    color: ${(props) => props?.theme?.colors?.white};

    svg {
      fill: ${(props) => props?.theme?.colors?.white};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 21px;
      margin-top: 20px;
      height: fit-content;
      width: fit-content;
    }
  }
`;

export const FilterButtonContainerWrapper = styled.div``;

export const DownArrowIconStyled = styled(DownArrowIcon)`
  width: 10px;
  height: 10px;
  margin-top: 2px;
`;

export const FilterIconStyled = styled(FilterIcon)`
  width: 24px;
  height: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
        width: 20px;
        height: 20px;
    }
  }
`;
