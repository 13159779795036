import styled from "styled-components";
import { ReactComponent as LogoImg } from "../../assets/images/svg/header-logo.svg";
import { ReactComponent as LogoImgReg } from "../../assets/images/svg/logo-for-reg.svg";
import { ReactComponent as Avatar } from "../../assets/images/svg/avatar.svg";
import { Box } from "@mui/material";

export const HeaderIconContainer = styled(Avatar)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  min-width: 0;
  border-radius: 100%;
  & svg {
    fill: ${(props) => props?.theme?.colors?.textColor};
  }
`;
export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px 16px 40px;
  width: 100%;
  height: 56px;
  background-color: ${(props) => props?.theme?.colors?.iconColor};
`;
export const HeaderSideContainer = styled(Box)`
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;
`;

export const Logo = styled(LogoImg)`
  width: 151px;
  height: 40px;
  display: inline-block;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 24px;
      width: 90px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100%;
      height: 100%;
    }
  }
`;

export const LogoReg = styled(LogoImgReg)`
  width: 151px;
  height: 40px;
  display: inline-block;
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 20px;
      width: 80px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 24px;
      width: 90px;
    }
  }
`;
