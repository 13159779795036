import React from "react";
import PropTypes from "prop-types";
import { REGISTER_STEP } from "constants/authConstants";
import {
  RightAuthContainer,
  ContentContainer,
  RegTitleContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import ClientStepOne from "./ClientSteps/ClientStepOne";
import ClientStepTwo from "./ClientSteps/ClientStepTwo";
import ClientStepThree from "./ClientSteps/ClientStepThree";

const ClientRegistrationContent = (props) => {
  return (
    <RightAuthContainer>
      <RegTitleContainer>{props.titleLabel}</RegTitleContainer>
      <ContentContainer>
        {props.currentStep == REGISTER_STEP.ONE && (
          <ClientStepOne
            currentStep={props.currentStep}
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />
        )}
        {props.currentStep == REGISTER_STEP.TWO && (
          <ClientStepTwo
            currentStep={props.currentStep}
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />
        )}
        {props.currentStep == REGISTER_STEP.THREE && (
          <ClientStepThree
            currentStep={props.currentStep}
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />
        )}
      </ContentContainer>
    </RightAuthContainer>
  );
};

ClientRegistrationContent.propTypes = {
  label: PropTypes.string,
  currentStep: PropTypes.number,
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  titleLabel: PropTypes.string,
};

export default ClientRegistrationContent;

