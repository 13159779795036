import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AuthType from "constants/authType";
import {
  Divider,
  Title,
  Message,
  CloseButton,
  StyledUserImg,
  StyledClientImg,
  Modal,
} from "./ValidationMessage.styled";

const ValidationMessage = (props) => {
  const { t } = useTranslation();
  const ImageComponent =
    props.type === AuthType.USER ? StyledUserImg : StyledClientImg;

  return (
      <Modal size={props.size} type={props.type}>
        <ImageComponent size={props.size}/>
        <Title>{props.title}</Title>
        <Message>{props.message}</Message>
        <Divider>
          <CloseButton type={props.type} onClick={props.onClose}>
            {t(`validationmessage.button`)}
          </CloseButton>
        </Divider>
      </Modal>
  );
};

ValidationMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default ValidationMessage;
