import Button from "components/Button/Button";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as LogInImage } from "assets/images/svg/mobile-login.svg";

export const RegisterLoginOptionsContainer = styled.div`
  display: flex;
  border: 1px solid ${(props) => props?.theme?.colors?.clientPageFormBorder};
  border-radius: 16px;
  padding: 12px 16px;
  gap: 16px;
`;

export const RegisterButton = styled(Button)`
  border-radius: 12px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  width: 138px;
  height: 33px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  color: ${(props) => props?.theme?.colors?.textColor2};
  background-color: ${(props) => props?.theme?.colors?.paginationHover};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.fieldBorderColor};
  }
`;

export const LoginButton = styled(RegisterButton)`
  border: none;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
  }
`;

export const LogInImageStyled = styled(LogInImage)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;