import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding-top: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-top: 0px;
    }
  }
`;

export const PopularAndFavouritesClientsContainerWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleContainerClients = styled.div`
  text-align: left;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.timeSlotText};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const PopularAndFavouritesClientsContainer = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: column;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
    }
  }
`;

export const OnlyPopularClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${(props) =>
    props?.isLoggedIn ? "width: calc((100% - 24px) / 2);" : "width: 100%"};
  ${(props) =>
    props?.isLoggedIn ? "padding-left: 26px;" : "padding-left: 0px;"};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding-left: 0px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding-left: 0px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-left: 0px;
      width: 100%;
    }
  }
`;

export const FavouriteClients = styled.div`
  display: flex;
  gap: 12px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const PopularClients = styled.div`
  display: flex;
  gap: 12px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const OnlyFavouriteClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc((100% - 24px) / 2);
  border-right: 1px solid
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.15)};
  padding-right: 25px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      border-right: 0px;
      width: 100%;
      padding-bottom: 16px;
      padding-right: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      border-right: 0px;
      width: 100%;
      padding-bottom: 16px;
      padding-right: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      border-right: 0px;
      width: 100%;
      padding-bottom: 16px;
    }
  }
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.timeSlotText};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const ServiceCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-height: 186px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      max-height: 140px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      max-height: 140px;
    }
  }
`;

export const CategoryList = styled.div`
  display: flex;
  gap: 12px;
  padding-right: 16px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  gap: 4px;
  padding-bottom: 16px;
`;

export const ScrollButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  width: 1px;
  height: 130px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  ${(props) => (props.left ? "left: 0;" : "right: 0;")}
`;
