import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/images/svg/black-plus-icon.svg";

export const ClientLogoWrapper = styled(Box)`
  min-width: 222px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 20px 20px 0px 20px;
  position: relative;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 12px;
      gap: 16px;
      min-width: 100px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 12px;
      flex-direction: row;
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 12px;
      flex-direction: row;
      gap: 16px;
    }
  }
`;

export const AddLogo = styled(PlusIcon)`
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  fill: black;
  cursor: pointer;
  position: absolute;
`;
export const DeleteButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
`;

export const LogoContainer = styled.div`
  position: relative;

  &:hover img {
    filter: ${(props) => (props.hasCustomImage ? "brightness(50%)" : "none")};
  }

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

export const ClientLogoStyled = styled.img`
  height: 141px;
  width: 141px;
  object-fit: cover;
  border-radius: 50%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 80px;
      width: 80px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 80px;
      width: 80px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 80px;
      width: 80px;
    }
  }
`;
export const ClientText = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
`;
