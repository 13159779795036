import React from "react";
import PropTypes from "prop-types";
import { GridContainer, RowContainer } from "./ListingServicesComponent.styled"; // Pretpostavljam da ćeš koristiti CSS Grid ili Flexbox za raspored
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import { useNavigate, useParams } from "react-router-dom";
import Item from "./Item";

const ListingServicesComponent = (props) => {
  const navigate = useNavigate();
  const { serviceCategoryId } = useParams();

  const onClickSchedule = (service) => {
    const pathParams = {
      serviceCategoryId: serviceCategoryId,
      clientId: props?.clientId,
      clientServiceId: service.id,
    };
    const resolvedRoute = replaceInRoute(
      PAGES.SCHEDULE_APPOINTMENT.route,
      pathParams
    );
    navigate(resolvedRoute);
  };

  return (
    <GridContainer>
      <RowContainer>
        {props.items.map((item, itemIndex) => (
          <Item
            key={itemIndex}
            item={item}
            itemIndex={itemIndex}
            onClickSchedule={onClickSchedule}
          />
        ))}
      </RowContainer>
    </GridContainer>
  );
};

ListingServicesComponent.propTypes = {
  items: PropTypes.array,
  clientId: PropTypes.string,
};

export default ListingServicesComponent;
