import Button from "components/Button/Button";
import styled from "styled-components";

export const ServiceParamesContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 35%;
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
      width: 50%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
    }
  }
`;

export const SearchForAppointmentsButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 32px;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      white-space: nowrap;
      width: 100%;
    }
  }
`;

export const MakeWishForAppointmentButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  padding: 6px 32px;
  color: ${(props) => props?.theme?.colors?.white};
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
  }
  height: 33px;
  line-height: 12px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
      line-height: 14px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      white-space: nowrap;
      width: 100%;
    }
  }
`;
