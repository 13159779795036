import React, { useMemo, useState } from "react";
import Table from "../../components/Table/Table";
import { useGetPaginatedFilteredUsersQuery } from "features/user/usersApiSlice";
import usersTableConstants from "constants/tableConstants/usersTableConstants";
import { useTranslation } from "react-i18next";
import Status from "components/Status/Status";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { userStatuses } from "constants/filters/statusConstants";
import { randomIdGenerator } from "util/randomGenerator";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { UsersIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import UserPageContent from "components/SuperAdmin/UserPageContent/UserPageContent";
import { replaceInRoute } from "util/routeHelpers";
import { PAGES } from "constants/pages";
import { useNavigate } from "react-router-dom";
import { removeFilter } from "util/filterHelper";
import filterValueToRemove from "constants/filters/filterValueToRemove";

const UsersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mapRowDataFunction = (rowData) =>
    rowData.data?.users?.map?.((user) => {
      return {
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        status: (
          <Status
            isActive={user.isActive}
            activeTextKey="superAdmin.table.clients.statusActive"
            inactiveTextKey="superAdmin.table.clients.statusInactive"
          />
        ),
      };
    });

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters(filters);
  };
  const mapCitiesFunction = (data) =>
    data.data.cityNames.map((name) => {
      return {
        id: name,
        name,
      };
    });

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"common.labelFirstName"}
          placeholder={"superAdmin.table.users.filters.userNameInput"}
          onChange={(firstName) =>
            setFilters((prevState) => {
              return {
                ...prevState,
                firstName: {
                  id: firstName,
                  name: firstName,
                  valueToRemove: filterValueToRemove.NAME,
                },
              };
            })
          }
          value={filters?.firstName?.id}
        />
        <TextFilter
          label={"common.labelLastName"}
          placeholder={"superAdmin.table.users.filters.userLastNameInput"}
          onChange={(lastName) =>
            setFilters((prevState) => {
              return {
                ...prevState,
                lastName: {
                  id: lastName,
                  name: lastName,
                  valueToRemove: filterValueToRemove.USER_LAST_NAME,
                },
              };
            })
          }
          value={filters?.lastName?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} >
        <TextFilter
          label={"common.labelEmail"}
          placeholder={"superAdmin.table.users.filters.emailInput"}
          onChange={(email) =>
            setFilters((prevState) => ({
              ...prevState,
              email: {
                id: email,
                name: email,
                valueToRemove: filterValueToRemove.EMAIL,
              },
            }))
          }
          value={filters?.email?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <SelectFilter
          label={"common.labelLocation"}
          placeholder={"superAdmin.table.users.filters.citySelect"}
          defaultValue={"superAdmin.table.users.filters.allCities"}
          onChange={(city) =>
            setFilters((prevState) => ({
              ...prevState,
              city: { ...city, valueToRemove: filterValueToRemove.CITY },
            }))
          }
          value={filters?.city}
          mapDataFunction={mapCitiesFunction}
          useQuery={useGetAllCitiesQuery}
        />
        <SelectFilter
          label={"common.status"}
          placeholder={"superAdmin.table.users.filters.statusSelect"}
          defaultValue={"superAdmin.table.users.filters.allStatuses"}
          onChange={(status) =>
            setFilters((prevState) => ({
              ...prevState,
              status: { ...status, valueToRemove: filterValueToRemove.STATUS },
            }))
          }
          value={filters?.status}
          items={userStatuses}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  const AddFilters = () => {};

  const [selectedUser, setSelectedUser] = useState(null);

  const userDetailsBtnClick = (user) => {
    const pathParams = { userId: user.id };
    const resolvedRoute = replaceInRoute(PAGES.USER.route, pathParams);
    navigate(resolvedRoute);
  };

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<UsersIconSelected />}
        onClick={AddFilters}
        isAddButtonShown={false}
        isFilterButtonShown={!selectedUser}
        filterComponents={filterComponents}
        appliedFilters={appliedFilters}
        filters={filters}
        removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
        saveFilters={saveFilters}
      />
      {selectedUser == null ? (
        <Table
          mapRowDataFunction={mapRowDataFunction}
          tableHeaders={usersTableConstants}
          usePaginatedQuery={useGetPaginatedFilteredUsersQuery}
          rowBtnClick={userDetailsBtnClick}
          appliedFilters={appliedFilters}
          numberOfCharactersPerCell={20}
          t={t}
        />
      ) : (
        <UserPageContent
          userId={selectedUser?.id}
          onClickBackBtn={() => setSelectedUser(null)}
        />
      )}
    </MainLayout>
  );
};

export default UsersPage;
