export default {
  app: {
    title: "Service Appointments",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  logo: {
    text: "Service Appointments",
  },
  common: {
    decline: "Decline",
    accept: "Accept",
    char: "characters",
    charsLimit: "You have exceeded the maximum character limit of ",
    serviceCategory: "Service Category",
    positiveOrNegativeNumber: "Must be positive or negative number.",
    positiveNumber: "Must be positive number.",
    finish: "Finish",
    serviceCategories: "serviceCategories",
    description: "Basic information",
    from: "from",
    language: "Language",
    english: "English",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelLocation: "Location",
    labelUsername: "Username",
    labelEmail: "Email",
    labelEmailAddress: "Email Address",
    labelPassword: "Password",
    labelCompanyName: "Company name",
    labelPrimaryService: "Primary service",
    labelCountry: "Country",
    labelCity: "City",
    labelAddress: "Address",
    labelPhone: "Phone",
    labelPhoneNumber: "Phone number",
    labelFirstName: "First name",
    labelLastName: "Last name",
    labelPasswordConfirmation: "Password confirmation",
    labelClientName: "Client name",
    labelClientFirstName: "Client first name",
    labelClientLastName: "Client last name",
    labelClientServiceName: "Service name",
    labelFromDate: "Service name",
    labelToDate: "Service name",
    clients: "Clients",
    status: "Status",
    usernameInput: "Input username",
    email: "Input email address",
    passwordInput: "Input password",
    companyNameInput: "Input company name",
    selectBtn: "Select",
    primaryServiceSelect: "Choose primary service",
    countrySelect: "Choose country",
    citySelect: "Choose city",
    addressInput: "Input address",
    phoneInput: "Input phone",
    firstNameInput: "Input first name",
    lastNameInput: "Input last name",
    passwordConfirmationInput: "Confirm password",
    codeInput: "Input code",
    next: "Next",
    nextTablePage: "Next",
    nextPage: "Next page",
    previousTablePage: "Previous",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    giveUp: "Give up",
    leave: "Leave",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    quit: "Quit",
    remove: "Remove",
    deactivate: "Deactivate",
    invite: "Invite",
    add: "Add",
    save: "Save",
    edit: "Edit",
    details: "Details",
    delete: "Delete",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    or: "Or",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    logout: "Log out",
    seeMore: "See more",
    users: "Users",
    filter: "Filter",
    schedule: "Schedule",
    resourceReservation: "Resource reservation",
    selectTimeRequired: "You must select a time with a date.",
  },
  imageGallery: {
    images: "Images",
    addImage: "Drag or add image",
  },
  code: {
    sentEmailMessage:
      "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentEmailCodeInput: "Input code sent to your email",
    sentPhoneMessage:
      "We have sent you a validation code to the email you previously specified. Please check your email address and input the code  in order to finish the process of registration.",
    labelSentPhoneCodeInput: "Input code sent as SMS message",
    sendNewCode: "Send new code",
  },
  landingPage: {
    popularClients: "Popular clients",
    favouriteClients: "Favorite clients",
  },
  notifications: {
    title: "Notifications",
    types: {
      verificationEmail: "Email verification",
      appointmentScheduled: "Scheduling appointment",
      appointmentCanceled: "Canceling appointment",
      clientStatusUpdated: "Updating client status",
      licenseUpdated: "Updating license",
      licenseExpired: "License expiration",
      appointmentStart: "Appointment start",
      appointmentScheduledNotification: "Appointment scheduling notification",
      appointmentCanceledNotification: "Appointment cancellation notification",
      licenseUpdatedNotification: "License update notification",
      licensePlusDeactivationUpdatedNotification:
        "License deactivation notification",
      licenseAddedNotification: "License addition notification",
      licenseDeletedNotification: "License deletion notification",
      accountStatusNotification: "Account status notification",
      appointmentRequested: "Appointment request",
      appointmentRequestAccepted: "Appointment request accepted",
      appointmentRequestDeclined: "Appointment request declined",
      userWishlistNotification: "User wishlist notification",
    },
  },
  pages: {
    landing: "Home",
    clientService: "Service",
    home: "Welcome to Termium",
    login: "Login",
    users: "Users",
    clients: "Clients",
    client: "Client",
    register: "Register",
    resources: "Resources",
    info: "Information",
    calendar: "Calendar",
    licenses: "Licenses",
    forgotPassword: "Forgot Password",
    resetPassword: "Reset password",
    error: "Error page",
    notFound: "Page not found",
    profile: "Profile page",
    settings: "Settings",
    dashboard: "Dashboard",
    services: "Services",
    controlTable: "Control Table",
    serviceCategories: "Categories",
    about: "About platform",
    help: "Help",
    contact: "Contact us",
    messages: "Messages",
    myAppointments: "My appointments",
    scheduling_appointment: "Scheduling appointments",
    myAccount: "My account",
  },
  register: {
    registerTitle: "Register",
    alreadyHaveAccount: "Already have account?",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordsDoNotMatch: "The passwords do not match.",
    passwordUppercase: "The passwords must have a upper case character.",
    passwordLowercase: "The passwords must have a lower case character.",
    passwordNumber: "The passwords must have a number.",
    passwordSpecial: "The passwords must have a special character.",
    inputRequired: "Input is required.",
    usernameAlreadyTaken: "This username is already in use.",
    emailAlreadyTaken: "This email is already in use.",
    codeLength: "Wrong code length",
    inccorrectCode: "Validation code is inccorrect",
    progress: {
      title: {
        client: "Register as a service provider in a few steps",
        user: "Register as a service user in a few steps",
      },
      client: {
        steps: [
          "Company information",
          "Personal information",
          "Email verification",
          "Personal information",
          "Email verification",
        ],
        mobileSteps: ["Company info.", "Personal info.", "Email verification"],
      },
      user: {
        steps: [
          "Personal information",
          "Additional information",
          "Email verification",
        ],
        mobileSteps: [
          "Personal info.",
          "Additional info.",
          "Email verification",
        ],
      },
    },
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 16 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address",
    password: "Please enter your password",
    logInTitle: "Login",
    logIn: "Log In",
    signUp: "Sign Up",
    continueWithGoogle: "Continue with google",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmailSent:
      "If you have account email will be delivered soon.",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  scheduleAppointment: {
    RedirectToLoginOrRegisterTitle: "You must be logged in to make appointment",
    chooseAttribute: "Choose attribute:",
    firstAvailable: "First available",
    searchForAppointments: "Search for appointments",
    chooseTime: "Choose time:",
    calendar: "Calendar",
    periodLabel: "Period:",
    dateLabel: "Date",
    noteLabel: "Note",
    notePlaceholder: "Enter a note",
    makeWishForAppointment: "Get notified about available time slots",
    makeWishForAppointmentTitle: "Add to wishlist",
    makeWishForAppointmentStartTime: "From",
    makeWishForAppointmentEndTime: "To",
    successfullyScheduledAppointment: "Successfully scheduled appointment",
    addClientToFav: "Do you want to add this client to your favorites list?",
    clientAddedToFavourites:
      "The client has been added to the list of favorites.",
  },
  appointments: {
    authorizeUserQuestion: "Allow user to make appointments without request?",
    UserAppointmentsConstant: "Scheduled appointments",
    WishListAppointmentsConstant: "Appointments wishlist",
    ClientEmployeeAppointmentsConstants: "Appointments as employee",
    cancelAppointmentQuestion:
      "Are you sure you want to cancel the appointment?",
    cancelAppointmentResponse: "Appointment successfully cancelled.",
    declineAppointmentRequestResponse:
      "Appointment request successfully declined.",
    acceptAppointmentRequestResponse:
      "Appointment request successfully accepted.",
    cancelReservedResourceResponse:
      "Resource reservation successfully cancelled.",
    tooltips: {
      inProgress: "The appointment is in progress",
      cancelled: "The appointment has been cancelled",
      done: "The appointment is over",
      allowedTimeExceeded:
        "The appointment is canceled up to {{allowedTime}} h before the realization",
    },
    addAppointment: "Add appointment",
    resourcesValidation: "At least 1 resource must be selected",
    clientServiceIdValidation: "Service must be",
    fromDate: "Start of appointment",
    toDate: "End of appointment",
    clientService: "Service",
    fromDatePlaceholder: "Choose starting date and time",
    toDatelaceholder: "Choose ending date and time",
    clientServicePlaceholder: "Choose service",
    filters: {
      fromDate: "From date",
      toDate: "To date",
      status: "Status",
      client: "Client",
      priceFrom: "Price from",
      priceTo: "Price to",
      inputClientServiceName: "Input service name",
    },
    appointmentStatus: {
      reserved: "Active",
      cancelled: "Cancelled",
      done: "Done",
      resourcesAllocation: "Reserved resources",
      cancelResourceAllocation: "Cancelled resources",
    },
    table: {
      date: "Date",
      time: "Time",
      clientName: "Client",
      serviceName: "Service",
      price: "Price",
    },
    noAppointmentsMessage:
      "You currently have no scheduled appointments. Once you schedule, upcoming appointments will appear here.",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    resetPassword: "Reset password",
    password: "Password",
    repeatPassword: "Repeat password",
    resetPasswordEmailText: "Enter email for reseting password",
    savePassword: "Save password",
    passwordChanged: "Password changed successfully",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset password",
    },
    sentEmailMessage:
      "Please check your email and follow the instructions in the message to create a new password.",
  },
  resetPassword: {
    label: "Reset password",
  },
  date: {
    timespan: {
      yearsAgo: "{{years}} years ago",
      monthsAgo: "{{months}} months ago",
      daysAgo: "{{days}} days ago",
      hoursAgo: "{{hours}} hours ago",
      minutesAgo: "{{minutes}} minutes ago",
      secondsAgo: "{{seconds}} seconds ago",
      now: "Right now",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Line chart",
      pieChart: "Pie chart",
      barChart: "Bar chart",
      radarChart: "Radar chart",
    },
  },
  middlepage: {
    alreadyGotAccount: "Already have an account? ",
    user: {
      title: "Register as a service <0>user</0>",
      text: "Do you want to quickly and easily book an appointment for the service you need?",
      button: "Register as a user",
    },
    client: {
      title: "Register as a service <0>provider</0>",
      text: "Are you providing services and need an easy way to schedule appointments?",
      button: "Register as a client",
    },
  },
  clientService: {
    newClientService: "New client Service",
    successfulyDeleted: "Successfully deleted",
    services: "Services",
    active: "Active",
    notActive: "Not Active",
    clientServiceSuccessfullyEdited: "Successfully edited setvice",
    clientServiceSuccessfullyAdded: "Successfully added setvice",
    serviceDeletePrompt: "Are you sure you want to delete the service?",
    addButonForServices: "Add new Service",
    service: "Service: {{serviceName}}",
    serviceName: "Service name",
    serviceStatus: "Service status",
    serviceDescription: "Service description",
    serviceDuration: "Service duration",
    servicePrice: "Service price",
    serviceActive: "Service active",
    serviceResources: "Resources",
    serviceAttributes: "Attributes",
    add: "Add",
    placeholderName: "Enter service name",
    placeholderDescription: "Enter description of service",
    placeholderDuration: "Enter duration of service",
    placeholderPrice: "Enter price of service",
    editResourceGroup: "Edit resource group",
    addResourceGroup: "Add resource group",
    resourceTypeName: "Resource type name",
    resourceList: "List of resources",
    attributeList: "List of attributes",
    placeholderResourceGroupName: "Enter resource group name",
    placeholderResource: "Enter resources",
    resourceAutoAssign: "Automatic resource assign",
    editAttribute: "Edit attribute",
    addAttribute: "Add attribute",
    attributeName: "Attribute name",
    attributeDescription: "Attribute description",
    attributeAdditionalTime: "Additional time",
    attributeAdditionalPrice: "Additional price",
    placeholderAttributeName: "Enter attribute name",
    placeholderAttributeDescription: "Enter attribute description",
    placeholderAttributeAdditionalTime: "Enter additional time of attribute",
    placeholderAttributeAdditionalPrice: "Enter additional price of attribute",
    table: {
      resources: {
        groupName: "Group name",
        autoAssign: "Automatic resource assign",
        resources: "Resources",
        addResource: "Add resource",
      },
      attributes: {
        name: "Name",
        description: "Description",
        additionalTime: "Additional time",
        additionalPrice: "Additional price",
        addAttribute: "Add attribute",
      },
    },
    filters: {
      minimalDurationInMinutesLabel: "Min duration",
      maximalDurationInMinutesLabel: "Max duration",
      minimalPriceLabel: "Price from",
      maximalPriceLabel: "Price to",
    },
    breadCrumbs: {
      placeholder: "Placeholder",
    },
  },
  validationmessage: {
    sentCodeTitle: "New code is sent to you!",
    client: {
      title: "You have successfully registered!",
      text: "You are ready for your first clients. Track appointments and manage appointments in a simple way.",
    },
    user: {
      title: "Your registration is complete!",
      text: "Now you can easily make appointments for the desired services",
    },
    button: "Close",
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  clientPanel: {
    moreInfo: "More information",
    lessInfo: "Less information",
    subscriptions: {
      addedToFavourites: "Added to favourites",
      addToFavourites: "Add to favourites",
      removedFromFavourites: "Removed from favourites",
      removeFromFavourites: "Remove from favourites",
    },
    infoSegment: {
      title: {
        workingHours: "Working hours",
        fullName: "Full name",
        primaryService: "Primary service",
        additionalServices: "Additional services",
        webAddress: "Website",
      },
    },
    servicesSegment: {
      title: "Services",
    },
  },
  serviceCategory: {
    selectService: "Select a service category",
    addNewServiceCategory: "Add new service category",
    editServiceCategory: "Edit service category",
    inputField: {
      label: {
        name: "Service *",
        description: "Service category *",
      },
      placeholder: {
        name: "Input new service",
        description: "Input service category",
      },
    },
  },
  home: {
    firstSection: {
      firstCardTitle:
        "Find services nearby and book appointments quickly and easily!",
      firstCardText:
        "Termium  gives you a quick and easy way to find an available appointment and book now!",
      secondCardNotLoggedInTitle:
        "Connect with users looking for your services!",
      secondCardNotLoggedInText:
        "Thanks to our platform, enable clients to find you and make an appointment in a few clicks. Sign up quickly and watch your appointments grow!",
      secondCardLoggedInTitle: "Your next appointments",
      secondCardLoggedInText:
        "You currently do not have a single appointment, when you make an appointment, you will be shown the next appointments that are waiting for you.",
      appointmentText: "{{date}} at {{time}}h - {{appointmentService}}",
    },
  },
  superAdmin: {
    pageTitle: "Super admin",
    dashboardTitle: "Dashboard",
    table: {
      numberOfShowingResultsBeforeSelect: "Show",
      numberOfShowingResultsAfterSelect: "from {{total}} data",
      tableHeaderForClients: "Clients",
      tableHeaderForUsers: "Users",
      tableHeaderForServiceCategories: "Service Categories",
      tableHeaderForServiceCategoriesDescription: "Service description",
      tableHeaderForLicenses: "Licenses",
      tableHeaderForResources: "Resources",
      editButton: "Edit",
      serviceCategories: {
        addButtonForServiceCategories: "Add new Service Category",
        editButton: "Edit",
        descriptionForServiceCategories: "Description",
        successfullyAdded: "Successfully added service category",
        successfullyUpdated: "Successfully updated service category",
      },
      licenses: {
        addButonForLicenses: "Add new License",
        updated: "Update date",
        issuedAtUtc: "Issued at:",
        expiresAtUtc: "Expires at:",
        endDate: "End date *",
        startDate: "Start date *",
        updatedAtUtc: "Updated",
        type: "Licence type:",
        selectLicenseType: "License type *",
        chooseLicence: "Choose licence",
        licenseType: {
          trial: "Trial",
          full: "Full",
        },
        addNewLicense: "Add License",
        editLicense: "Edit License",
        successfullyUpdated: "License successfully updated",
        successfullyAdded: "License successfully added",
        status: "Status:",
        lastChanged: "Last modified:",
        statusActive: "Active",
        statusInactive: "Inactive",
        statusValid: "Valid",
        statusInvalid: "Invalid",
        startDateRequired: "Start date of the license is required",
        endDateRequired: "End date of the license is required",
        licenseTypeRequired: "License type is required",
        startAndEndNotGood: "End date cannot be earlier than the start date",
        licenseDeletePrompt: "Are you sure you want to delete this license?",
        licenseDeleted: "License successfully deleted",
      },
      clients: {
        addButtonForClients: "Add new Client",
        locationForClients: "Location",
        statusForClients: "Status",
        statusActive: "Active",
        statusInactive: "Inactive",
        labelStatusActive: "Client active:",
        labelStatusInactive: "Client inactive:",
        userPanel: "User panel:",
        successfullyUpdatedClient: "Information saved!",
        successfullyUpdatedClientLogo: "Logo saved!",
        unsuccessfullyUpadtedClient: "Information not saved!",
        confirmationStatusChangeMessage:
          "Are you sure you want to {{action}} client?",
        successfullyChangedStatus: "Successfully changed client status",
        unsuccessfullyChangedStatus: "Unsuccessfully changed client status",
        unsavedData:
          "Your changes will not be saved, are you sure you want to save the page?",
        filters: {
          clientNameInput: "Input client name",
          citySelect: "Choose city",
          allCities: "All cities",
          statusSelect: "Choose status",
          allStatuses: "All statuses",
          primaryServiceSelect: "Choose primary service",
          allServices: "All services",
        },

        details: {
          fullName: "Name:",
          registrationName: "Full name:",
          email: "E-mail address:",
          phoneNumber: "Phone number:",
          country: "Country:",
          city: "City:",
          workDays: "Work days:",
          userBookingSetting: "User booking:",
          primaryServiceCategory: "Primary service category:",
          webAddress: "Web page:",
          address: "Address:",
          workHours: "Working hours:",
          clientStatus: "Client Status:",
          appointmentStartTimeSetting: "Appointment starting intervals:",
          requiredDescription: "Add description",
        },
        tabs: {
          informations: "Information",
          resources: "Resources",
          services: "Services",
          calendar: "Calendar",
          license: "License",
        },
      },
      users: {
        addButtonForUsers: "Add New User",
        filters: {
          userNameInput: "Enter firstname",
          userLastNameInput: "Enter lastname",
          emailInput: "Enter email",
          citySelect: "Select city",
          allCities: "All cities",
          statusSelect: "Select status",
          allStatuses: "All statuses",
        },
        labelStatusActive: "Active:",
        labelStatusInactive: "Inactive:",
        buttonActivate: "Activate",
        buttonDeactivate: "Deactivate",
        confirmationStatusChangeMessage:
          "Are you sure you want to {{action}} this user?",
        toActivate: "activate",
        toDeactivate: "deactivate",
        statusActive: "Active",
        statusInactive: "Inactive",
      },
      resources: {
        name: "Resource name",
        type: "Resource type",
        resourceType: {
          employee: "Employee",
          equipment: "Equipment",
          premise: "Premise",
        },
        inputField: {
          label: {
            name: "Resource name",
            type: "Resource type",
          },
          placeholder: {
            name: "Input resource name",
            type: "Input resource type",
          },
        },
        status: "Resource status",
        addResource: "Add resource",
        editResource: "Edit resource",
        statusActive: "Active",
        statusInactive: "Inactive",
        addNewResource: "Add new resource",
        confirmationResourceDeletionMessage:
          "Are you sure you want to delete resource?",
        successfullyRemoved: "Successfully removed resource",
        unsuccessfullyRemoved: "Unsuccessfully removed resource",
        successfullyAdded: "Successfully added resource",
        successfullyUpdated: "Successfully updated resource",
        successfullyChangedStatus: "Successfully changed resource status",
        unsuccessfullyChangedStatus: "Unsuccessfully changed resource status",
      },
      clientResources: {
        resource: "Resource type",
        addResource: "Add resource",
        editResource: "Edit resource",
        chooseResource: "Choose resource type",
        resourceType: {
          employee: "Employee",
          equipment: "Equipment",
          premise: "Premise",
          other: "Other",
        },
      },
    },
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client IP address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  profile: {
    image: "Profile Image",
    chooseImage: "Choose Profile Image",
    form: {
      step1: "Personal Information",
      step2: "Address",
      step3: "Job",
    },
    labels: {
      firstName: "First Name",
      lastName: "Last Name",
      gender: "Gender",
      city: "City",
      street: "Street",
      postalCode: "Postal Code",
      companyName: "Company Name",
      companyAddress: "Company Address",
      yearsInCompany: "Years in Company",
    },
  },
  user: {
    breadCrumbs: {
      placeholder: "Placeholder",
    },
    deleteAccount: "Delete account",
  },
  client: {
    breadCrumbs: {
      placeholder: "Placeholder",
    },
  },
  footer: {
    title:
      "Termium<span> Copyright © 2024 <0 /> <1>Tremium Software.</1></span> All rights reserved.",

    left: "Termium <0>Copyright</0> © <0>2024</0>",
    companyName: "Tremium Software. ",
    rights: " All rights reserved.",
  },

  sidebar: {
    controlTable: "Control Table",
    services: "Services",
    clients: "Clients",
    users: "Users",
  },
  image: {
    error: "Error loading image",
    loading: "Loading...",
  },
  workingHours: {
    workingHoursTitle: "Working Hours",
    bookingAllowed: "Booking Allowed",
    bookingNotAllowed: "Booking Not Allowed",
    appointmentBeginning: "Start of appointment on",
    workingDay: "Working days",
    workingHours: "Working Hours from",
    notWorking: "Not Working",
    timeBeforeCancellationAllowed: "Cancellation timeframe (h)",
    from: "From *",
    to: "To *",
    chooseTime: "Choose time",
    startAndEndNotGood: "End time must be after start time",
    startTimeRequired: "Start time is required",
    endTimeRequired: "End time is required",
    timeOverlap: "Selected time overlaps with existing",
    inputPositive: "Only positive number",
  },
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    nonWorkingDay: "Non-working",
  },
  notification: {
    newNotification: "You have received a new notification!",
    newNotifications: "You have received {{count}} new notifications!",
    noNotifications: "No notifications",
  },
  calendar: {
    day: "Day",
    week: "Week",
    month: "Month",
    agenda: "Agenda",
    date: "Date",
    time: "Time",
    event: "Event",
    noEventsInRange: "There are no events in this range.",
    cancel: "Cancel appointment",
    start: "Appointment start: ",
    end: "Appointment end: ",
    clientName: "Client name: ",
    phone: "Client phone number: ",
    price: "Price: ",
    fullPrice: "Full price: ",
    includedAttributes: "Included: ",
    additionalPrice: "Additional price: ",
    includedResources: "Employee: ",
    startAndEndNotGood: "End date cannot be earlier than the start date",
    resevedFrom: "Reserved from: ",
    reservedTo: "Reserved to: ",
    cancelReservation: "Cancel reservation",
    resevedResources: "Reserved: ",
    info: "Information",
    note: "Note: ",
  },
};
