import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  InputFieldContainer,
  InputFieldsContainer,
  SentCodeBtnContainer,
  SentCodeMessageContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import SentCodeField from "components/RegistrationContent/InputFields/SentCodeField";
import { useTranslation } from "react-i18next";
import SendNewCodeButton from "components/RightAuthContent/Buttons/SendNewCodeButton/SendNewCodeButton";
import { useFormik } from "formik";
import { ClientStepThreeAtributes } from "initialValues/clientRegInitialValues";
import { ClientStepThreeValidation } from "validations/registerClientValidation";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import AuthType from "constants/authType";
import { useRequestEmailVerificationCodeMutation } from "features/register/userRegisterApiSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  selectClientAttributesStepOne,
  selectClientAttributesStepTwo,
} from "features/register/registerClientSlice";
import { clientColors } from "themes/secondaryTheme/secondaryThemeColors";
import {
  setFinishedRegistrationModal,
  setSentCodeModal,
} from "features/modal/modalSlice";
import { useRegisterClientMutation } from "features/register/registerApiSlice";

const ClientStepThree = (props) => {
  const { t } = useTranslation();
  const [requestEmailVerificationCode] =
    useRequestEmailVerificationCodeMutation();
  const [register] = useRegisterClientMutation();
  const [verificationCodeError, setVerificationCodeError] = useState(null);
  const dispatch = useDispatch();

  const clearVerificationCodeErrorMessage = () => {
    if (verificationCodeError != null) {
      setVerificationCodeError(null);
    }
  };

  const clientData = useSelector(selectClientAttributesStepTwo);
  const clientDataStepOne = useSelector(selectClientAttributesStepOne);

  const toSubmit = {
    registrationName: clientDataStepOne?.registrationName,
    fullName: clientDataStepOne?.fullName,
    country: clientDataStepOne?.country.id,
    city: clientDataStepOne?.city.id,
    address: clientDataStepOne?.address,
    phoneNumber:
      clientDataStepOne?.countryPhoneCode + clientDataStepOne?.phoneNumber,
    firstName: clientData?.firstName,
    lastName: clientData?.lastName,
    email: clientData?.email,
    password: clientData?.password,
    serviceCategoryId: clientDataStepOne?.serviceCategoryId.primaryId,
  };

  const handleSubmit = async () => {
    const response = await register({
      ...toSubmit,
      verificationCode: formik.values.sentEmailCode,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            setVerificationCodeError(t("register.inccorrectCode"));
            break;
        }
      });
    } else {
      dispatch(setFinishedRegistrationModal(AuthType.CLIENT));
    }
  };

  const onClickSendNewCode = async () => {
    const response = await requestEmailVerificationCode({
      email: clientData.email,
      firstName: clientData.firstName,
      lastName: clientData.lastName,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            setVerificationCodeError(error.message);
            break;
        }
      });
    } else {
      dispatch(setSentCodeModal(AuthType.CLIENT));
    }
  };

  const formik = useFormik({
    initialValues: ClientStepThreeAtributes,
    validationSchema: ClientStepThreeValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <SentCodeMessageContainer>
            {t("code.sentEmailMessage")}
          </SentCodeMessageContainer>
          <InputFieldContainer>
            <SentCodeField
              formik={formik}
              required
              helperText={verificationCodeError}
              clearErrorMessage={clearVerificationCodeErrorMessage}
            />
          </InputFieldContainer>
          <SentCodeBtnContainer>
            <SendNewCodeButton
              onClick={onClickSendNewCode}
              colorPalet={clientColors}
            />
          </SentCodeBtnContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerRegContent
        lastStep={true}
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={props.onClickBackBtn}
        currentStep={props.currentStep}
        colorPalet={clientColors}
        gap={true}
      />
    </>
  );
};

ClientStepThree.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
};

export default ClientStepThree;
