import { Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "assets/images/svg/downArrow.svg";
import { ReactComponent as ArrowUp } from "assets/images/svg/upArrow.svg";

export const ClientInfoContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const ClientName = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 20px;
      line-height: 19px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 16px;
      line-height: 19px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const BasicInfo = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27.28px;
  color: ${(props) => props?.theme?.colors?.textColor2};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 18px;
      line-height: 19px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      text-align: left;
      gap: 4px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      text-align: left;
      gap: 4px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      text-align: left;
      gap: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      text-align: left;
      gap: 12px;
    }
  }
`;

export const SegmentWrapper = styled.div`
  gap: 6px;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 6px;

  ${(props) => props.theme.breakpoints.rotate("sm")} {
    && {
      padding: 12px;
      padding-top: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 12px;
      padding-top: 0px;
    }
  }
`;

export const DividerLine = styled.div`
  border-top: 2px solid ${(props) => props?.theme?.colors?.textUncompletedStep};
`;

export const SegmentWithBottomBorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 2px solid
    ${(props) => props?.theme?.colors?.textUncompletedStep};
  gap: 6px;
`;

export const ClientDescription = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const ClientWorkingHour = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const CustomTextWrapper = styled.div``;

export const ClientFullName = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const ClientPrimaryService = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const ClientWebAddress = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const SegmentTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  align-items: center;
  justify-content: end;
`;

export const MoreInfoTitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props?.theme?.colors?.textColor2};
`;

export const ArrwoDownStyled = styled(ArrowDown)`
  margin-right: 10px;
  cursor: pointer;
`;

export const ArrowUpStyled = styled(ArrowUp)`
  margin-right: 10px;
  cursor: pointer;
`;
