import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    serviceCategoryModal: false,
    serviceCategoryModalProps: {
      serviceCategory: null,
    },
    sentCodeModal: false,
    sentCodeModalProps: {
      authType: null,
    },
    finishedRegistrationModal: false,
    finishedRegistrationModalProps: {
      authType: null,
    },
    changeUserStatusModal: false,
    changeUserStatusModalProps: {
      userId: null,
      active: null,
      userType: null,
    },
    changeLicenceModal: false,
    changeLicenceModalProps: {
      data: null,
      clientId: null,
      authType: null,
    },
    deleteLicenceModal: false,
    deleteLicenceModalProps: {
      id: null,
      authType: null,
    },
    deleteResourceModal: false,
    deleteResourceModalProps: {
      resourceId: null,
    },
    addEditResourceModal: false,
    addEditResourceModalProps: {
      resource: null,
    },
    workingTimeModal: false,
    workingTimeModalProps: {
      day: null,
      onAddWorkingHour: null,
    },
    unsavedAlertModal: false,
    unsavedAlertModalProps: {
      path: null,
    },
    cancelAppointmentModal: false,
    cancelAppointmentModalProps: {
      appointment: null,
    },
    fullImageModal: false,
    fullImageModalProps: {
      image: null,
    },
    addClientToFavModal: false,
    addClientToFavModalProps: {
      userId: null,
      clientId: null,
    },
    addAppointmentModal: false,
    addAppointmentModalProps: {
      client: null,
    },
    calendarInformationAndCancellationModal: false,
    calendarInformationAndCancellationModalProps: {
      appointment: null,
      isUser: null,
    },
    calendarResourcesInformationAndCancellationModal: false,
    calendarResourcesInformationAndCancellationModalProps: {
      resource: null,
    },
    loadingModal: false,
    filterModal: false,
    makeWishForAppointmentModal: false,
    makeWishForAppointmentModalProps: {
      clientService: null,
      fromTime: null,
      toTime: null,
    },
    redirectionForMakingAppointmentModal: false,

    appointmentRequestModal: false,
    appointmentRequestModalProps: {
      appointmentId: null,
    },
    acceptAppointmentRequestModal: false,
    acceptAppointmentRequestModalProps: {
      appointment: null,
    },
  },
  clientServiceAttributeModal: false,
  clientServiceAttributeModalProps: {
    selectedItem: null,
    onCancel: null,
  },
  clientServiceResourceModal: false,
  clientServiceResourceModalProps: { selectedItem: null, onCancel: null },

  clientServiceDeleteModal: false,
  clientServiceDeleteModalProps: { selectedItem: null, onDelete: null },

  reducers: {
    setAcceptAppointmentRequestModal: (state, action) => {
      return {
        ...state,
        acceptAppointmentRequestModal: true,
        acceptAppointmentRequestModalProps: {
          appointment: action.payload,
        },
      };
    },
    setAppointmentRequestModal: (state, action) => {
      return {
        ...state,
        appointmentRequestModal: true,
        appointmentRequestModalProps: {
          appointmentId: action.payload.appointmentId,
          notificationType: action.payload.notificationType,
        },
      };
    },
    setRedirectionForAppointmentModal: () => {
      return {
        redirectionForMakingAppointmentModal: true,
      };
    },
    setMakeWishForAppointmentModal: (state, action) => {
      return {
        ...state,
        makeWishForAppointmentModal: true,
        makeWishForAppointmentModalProps: {
          clientService: action.payload?.clientService,
          fromTime: action.payload?.fromTime,
          toTime: action.payload?.toTime,
        },
      };
    },
    setAddAppointmentModal: (state, action) => {
      return {
        ...state,
        addAppointmentModal: true,
        addAppointmentModalProps: {
          client: action.payload,
        },
      };
    },
    setCalendarInformationAndCancellationModal: (state, action) => {
      return {
        ...state,
        calendarInformationAndCancellationModal: true,
        calendarInformationAndCancellationModalProps: {
          appointment: action.payload?.appointment,
          isUser: action.payload?.isUser,
        },
      };
    },
    setCalendarResourcesInformationAndCancellationModal: (state, action) => {
      return {
        ...state,
        calendarResourcesInformationAndCancellationModal: true,
        calendarResourcesInformationAndCancellationModalProps: {
          resource: action.payload?.resource,
        },
      };
    },
    setCancelAppointmentModal: (state, action) => {
      return {
        ...state,
        cancelAppointmentModal: true,
        cancelAppointmentModalProps: {
          appointment: action.payload,
        },
      };
    },
    setServiceCategoryModal: (state, action) => {
      return {
        ...state,
        serviceCategoryModal: true,
        serviceCategoryModalProps: {
          serviceCategory: action.payload,
        },
      };
    },
    setWorkingHoursModal: (state, action) => {
      return {
        ...state,
        workingTimeModal: true,
        workingTimeModalProps: {
          day: action.payload.day,
          onAddWorkingHour: action.payload.onAddWorkingHour,
        },
      };
    },
    setAddClientToFavModal: (state, action) => {
      return {
        ...state,
        addClientToFavModal: true,
        addClientToFavModalProps: {
          userId: action.payload.userId,
          clientId: action.payload.clientId,
        },
      };
    },
    unsetShownModal: (state) => {
      state.appointmentRequestModal = false;
      state.redirectionForMakingAppointmentModal = false;
      state.serviceCategoryModal = false;
      state.sentCodeModal = false;
      state.finishedRegistrationModal = false;
      state.changeUserStatusModal = false;
      state.changeLicenceModal = false;
      state.deleteLicenceModal = false;
      state.clientServiceAttributeModal = false;
      state.clientServiceResourceModal = false;
      state.deleteResourceModal = false;
      state.addEditResourceModal = false;
      state.workingTimeModal = false;
      state.unsavedAlertModal = false;
      state.clientServiceDeleteModal = false;
      state.cancelAppointmentModal = false;
      state.acceptAppointmentRequestModal = false;
      state.fullImageModal = false;
      state.addClientToFavModal = false;
      state.addAppointmentModal = false;
      state.makeWishForAppointmentModal = false;
      state.loadingModal = false;
      state.filterModal = false;
      state.calendarInformationAndCancellationModal = false;
      state.calendarResourcesInformationAndCancellationModal = false;
    },
    setSentCodeModal: (state, action) => {
      return {
        ...state,
        sentCodeModal: true,
        sentCodeModalProps: {
          authType: action.payload,
        },
      };
    },
    setFinishedRegistrationModal: (state, action) => {
      return {
        ...state,
        finishedRegistrationModal: true,
        finishedRegistrationModalProps: {
          authType: action.payload,
        },
      };
    },
    setChangeUserStatusModal: (state, action) => {
      return {
        ...state,
        changeUserStatusModal: true,
        changeUserStatusModalProps: {
          userId: action.payload.userId,
          active: action.payload.active,
          userType: action.payload.userType,
        },
      };
    },
    setChangeLicenceModal: (state, action) => {
      return {
        ...state,
        changeLicenceModal: true,
        changeLicenceModalProps: {
          data: action.payload.data,
          clientId: action.payload.clientId,
          authType: action.payload.authType,
        },
      };
    },
    setDeleteLicenceModal: (state, action) => {
      return {
        ...state,
        deleteLicenceModal: true,
        deleteLicenceModalProps: {
          id: action.payload.id,
          authType: action.payload.authType,
        },
      };
    },
    setDeleteResourceModal: (state, action) => {
      return {
        ...state,
        deleteResourceModal: true,
        deleteResourceModalProps: {
          clientId: action.payload.clientId,
          resourceId: action.payload.resourceId,
        },
      };
    },
    setAddEditResourceModal: (state, action) => {
      return {
        ...state,
        addEditResourceModal: true,
        addEditResourceModalProps: {
          ...action.payload,
        },
      };
    },
    setUnsavedAlertModal: (state, action) => {
      return {
        ...state,
        unsavedAlertModal: true,
        unsavedAlertModalProps: {
          ...action.payload,
        },
      };
    },
    setClientServiceAttributeModal: (state, action) => {
      return {
        ...state,
        clientServiceAttributeModal: true,
        clientServiceAttributeModalProps: {
          selectedItem: action.payload.selectedItem,
          onCancel: action.payload.onCancel,
        },
      };
    },
    setClientServicResourceModal: (state, action) => {
      return {
        ...state,
        clientServiceResourceModal: true,
        clientServiceResourceModalProps: {
          selectedItem: action.payload.selectedItem,
          onCancel: action.payload.onCancel,
        },
      };
    },
    setClientServiceDeleteModal: (state, action) => {
      return {
        ...state,
        clientServiceDeleteModal: true,
        clientServiceDeleteModalProps: {
          selectedItem: action.payload.selectedItem,
          onDelete: action.payload.onDelete,
          onCancel: action.payload.onCancel,
        },
      };
    },
    setFullImageModal: (state, action) => {
      return {
        ...state,
        fullImageModal: true,
        fullImageModalProps: {
          imageUrl: action.payload,
        },
      };
    },
    setLoadingModal: (state) => {
      return {
        ...state,
        loadingModal: true,
      };
    },
    unsetLoadingModal: (state) => {
      return {
        ...state,
        loadingModal: false,
      };
    },
    setFilterModal: (state) => {
      return {
        ...state,
        filterModal: true,
      };
    },
    unsetFilterModal: (state) => {
      return {
        ...state,
        filterModal: false,
      };
    },
  },
});
export const {
  setAcceptAppointmentRequestModal,
  setAppointmentRequestModal,
  setRedirectionForAppointmentModal,
  setMakeWishForAppointmentModal,
  setServiceCategoryModal,
  setWorkingHoursModal,
  setSentCodeModal,
  setFinishedRegistrationModal,
  setChangeUserStatusModal,
  unsetShownModal,
  setChangeLicenceModal,
  setDeleteLicenceModal,
  setDeleteResourceModal,
  setAddEditResourceModal,
  setUnsavedAlertModal,
  setClientServiceAttributeModal,
  setClientServicResourceModal,
  setClientServiceDeleteModal,
  setCancelAppointmentModal,
  setFullImageModal,
  setAddClientToFavModal,
  setAddAppointmentModal,
  setLoadingModal,
  unsetLoadingModal,
  setFilterModal,
  unsetFilterModal,
  setCalendarInformationAndCancellationModal,
  setCalendarResourcesInformationAndCancellationModal,
} = modalSlice.actions;
export default modalSlice.reducer;

export const modalSelector = (state) => state.modal;

export const selectModal = createSelector(modalSelector, (state) => {
  return state;
});
