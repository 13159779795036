import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  ContentWrapper,
  ContentWrapperCentered,
  MainContainer,
} from "./MainLayout.styled";
import Footer from "components/Footer/Footer";
import { Sidebar } from "components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { selectIsSidebarCollapsed } from "features/sidebar/sidebarSlice";
import { ContentCentered } from "./AuthLayout.syted";
import useDevice from "hooks/useDevice";

const MainLayout = (props) => {
  const isCollapsed = useSelector(selectIsSidebarCollapsed);
  const { isMobile } = useDevice();

  return (
    <MainContainer isAuthLayout={props?.isAuthLayout}>
      {!props?.isAuthLayout && !isMobile && <Sidebar />}
      {props?.isAuthLayout ? (
        <ContentWrapperCentered>
          <ContentCentered>{props?.children}</ContentCentered>
          <Footer authLayout />
        </ContentWrapperCentered>
      ) : (
        <ContentWrapper collapsed={isCollapsed}>
          <Content>{props?.children}</Content>
          <Footer />
        </ContentWrapper>
      )}
    </MainContainer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  isAuthLayout: PropTypes.bool,
};

export default MainLayout;
