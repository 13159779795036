import { useEffect } from "react";
import { useHubConnection } from "hooks/useHubConnection";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  selectUnreadNotificationsCount,
} from "features/notificationsSlice/notificationsSlice";
import { makeToastMessage } from "util/toastMessage";
import { useTranslation } from "react-i18next";

export const useNotifications = (refetchNotifications) => {
  const { t } = useTranslation();
  const { connection } = useHubConnection();
  const dispatch = useDispatch();
  const numberOfMessages = useSelector(selectUnreadNotificationsCount);

  useEffect(() => {
    if (connection) {
      connection.on("ReceiveNotification", (message) => {
        dispatch(addNotification(message, message.type));
        refetchNotifications();
      });

      return () => {
        connection.off("ReceiveNotification");
      };
    }
  }, [connection, dispatch, refetchNotifications]);

  useEffect(() => {
    if (numberOfMessages > 1) {
      makeToastMessage(
        t("notification.newNotifications", { count: numberOfMessages }),
        "notification"
      );
    } else if (numberOfMessages === 1) {
      makeToastMessage(t("notification.newNotification"), "notification");
    }
  }, [numberOfMessages]);
};
