import React, { useEffect, useRef } from "react";
import {
  ArrowImage,
  CollapsedLogoContainer,
  LeftArrowImage,
  LogoContainer,
  MobileCollapsedLogoContainer,
  MobileLogoContainer,
  MobileSidebarContainer,
  RightArrowImage,
  SidebarContainer,
  StyledLink,
  TabsContainer,
} from "./Sidebar.styled";
import { Tooltip } from "@mui/material";
import sidebarNavigationConstants from "constants/sidebarNavigationConstants";
import SidebarNavigationItem from "./SidebarTabs/SidebarNavigationItem";
import WithPermissions from "components/WithPermissions/WithPermissions";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "features/sidebar/sidebarSlice";
import { selectIsSidebarCollapsed } from "features/sidebar/sidebarSlice";
import { useTranslation } from "react-i18next";
import useDevice from "hooks/useDevice";
import { useNavigate } from "react-router-dom";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCollapsed = useSelector(selectIsSidebarCollapsed);
  const { isMobile } = useDevice();
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  const goToHomePage = () => {
    navigate(PAGES.HOME.route);
  }

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(toggleSidebar());
    }
  };

  useEffect(() => {
    if (isMobile && !isCollapsed) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (isMobile && !isCollapsed) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [isMobile, isCollapsed]);

  const renderSidebarNavigationItem = (
    singleNavigationConstant,
    isCollapsed
  ) => {
    return (
      <SidebarNavigationItem
        collapsed={isCollapsed}
        path={singleNavigationConstant.path}
        icon={singleNavigationConstant.icon}
        title={singleNavigationConstant?.title}
        iconSelected={singleNavigationConstant.iconSelected}
      />
    );
  };

  return (
    <>
    {isMobile && isCollapsed && (
      <MobileSidebarContainer>
        <MobileCollapsedLogoContainer onClick={handleSidebarToggle} />
        <MobileLogoContainer onClick={goToHomePage} />
      </MobileSidebarContainer>
    )}
    <SidebarContainer
      ref={sidebarRef}
      collapsed={isCollapsed}
      isMobile={isMobile}
    >
      <StyledLink to={PAGES.BASE.route}>
        {!isCollapsed ? <LogoContainer /> : <CollapsedLogoContainer />}
      </StyledLink>
      <TabsContainer collapsed={isCollapsed}>
        {sidebarNavigationConstants.map((singleNavigationConstant) => (
          <WithPermissions
            key={singleNavigationConstant.path}
            roles={singleNavigationConstant.hasRole}
          >
            {isCollapsed ? (
              <Tooltip
                title={t(singleNavigationConstant?.title)}
                placement="right"
                disableInteractive
              >
                <div>
                  {renderSidebarNavigationItem(
                    singleNavigationConstant,
                    isCollapsed
                  )}
                </div>
              </Tooltip>
            ) : (
              renderSidebarNavigationItem(singleNavigationConstant, isCollapsed)
            )}
          </WithPermissions>
        ))}
      </TabsContainer>
      <ArrowImage>
        <RightArrowImage
          showArrow={isCollapsed}
          onClick={handleSidebarToggle}
        />
        <LeftArrowImage hideArrow={isCollapsed} onClick={handleSidebarToggle} />
      </ArrowImage>
    </SidebarContainer>
    </>
  );
};

export default Sidebar;
