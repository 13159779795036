import { Typography } from "@mui/material";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 16px;
      align-items: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      gap: 16px;
      align-items: normal;
    }
  }
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32.74px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const ContentContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
