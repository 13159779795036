import { Typography } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

const LongDashStyled = styled(Typography)`
  background-color: ${(props) => props?.theme?.colors?.black};
  width: 100%;
  height: 1px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.6)};
    height: 0.5px;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Poppins" !important;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.6)};
  }
`;

export default LongDashStyled;
