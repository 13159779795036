import {
  Box,
  Container,
  Pagination,
  TableBody,
  TableCell,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NotificationStyledTypography = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: black;
`;

export const NotificationRowContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700px;
  background-color: ${({ processed, read, theme }) =>
    processed || read
      ? theme.colors.rowBackgroundColor
      : theme.colors.toastWarningColor};
  padding: 16px;
  margin-top: 12px;
  border: 1px solid ${({ theme }) => theme.colors.textUncompletedStep};
  border-radius: 16px;
`;

export const StyledTableCell = styled(TableCell)`
  border: none;
`;

export const StyledNotificationRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  & .MuiPaginationItem-root {
    &.Mui-selected {
      background-color: ${(props) => props?.theme?.colors?.darkBlue};
      color: ${(props) => props?.theme?.colors?.textWhite};
    }

    &:hover:not(.Mui-selected) {
      background-color: ${(props) => props?.theme?.colors?.darkBlue};
    }
  }
`;

export const NotificationStyledContainer = styled(Container)`
  &.MuiContainer-root {
    max-width: 100%;
  }
`;

export const NotificationListTableBody = styled(TableBody)``;
