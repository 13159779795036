import React, { useEffect, useState } from "react";
import { languageOptions } from "constants/languages";
import CustomSelectFieldStyled, {
  DownArrowStyled,
  menuProps,
} from "./LanguageSelector.styled";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDevice from "hooks/useDevice";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);
  const { isMobile } = useDevice();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("Language");
    const selectedItem = languageOptions.find(
      (item) => item.value === savedLanguage
    );
    setSelectedValue(selectedItem);
  }, []);

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    const selectedItem = languageOptions.find(
      (item) => item.value === selectedLanguage
    );
    setSelectedValue(selectedItem);
    localStorage.setItem("Language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <CustomSelectFieldStyled
      name="Language"
      value={selectedValue?.value || ""}
      displayEmpty
      onChange={handleChange}
      renderValue={(selected) => {
        return selected
          ? languageOptions.find((item) => item.value === selected)?.label
          : "";
      }}
      IconComponent={isMobile ? null : DownArrowStyled}
      MenuProps={menuProps}
    >
      {languageOptions.map((item) => (
        <MenuItem key={item.id} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </CustomSelectFieldStyled>
  );
};

LanguageSelector.propTypes = {};

export default LanguageSelector;
