import Button from "components/Button/Button";
import styled from "styled-components";

export const BackButtonContainer = styled(Button)`
  color: ${(props) => props?.theme?.colors?.uncompletedRegistration};

  ${(props) => props.theme.breakpoints.down("sm")} {
      width: 67px;
    }
`;
