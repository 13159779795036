import styled from "styled-components";
import { ReactComponent as CheckImg } from "../../assets/images/svg/check-solid.svg";
import { ReactComponent as LogInImg } from "../../assets/images/svg/log-in-img.svg";
import AuthType from "constants/authType";

export const Container = styled.div`
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-top: 16px;
      width: 100%;
      height: 144px;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
`;

export const LogImg = styled(LogInImg)`
  width: 100%;
  height: auto;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: fill;
    }
  }
`;

export const CheckIcon = styled(CheckImg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.white};
    fill: ${(props) => props?.theme?.colors?.white};
  }
  width: 24px;
  height: 24px;
  display: inline-block;

  ${(props) => props.theme.breakpoints.rotate()} {
    width: 12px;
    height: 12px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 16px;
    height: 16px;
  }
`;

export const ProgressContainer = styled.div`
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  padding: 45px;
  gap: 10px;
  border-radius: 15px;
  width: 460px;
  height: 692px;
  border-radius: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 45px 20px;
      transition: padding 0.3s ease-in-out;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 8px 12px;
      transition: padding 0.3s ease-in-out;
      height: 90%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 12px;
      max-width: 311px;
      width: 100%;
      height: auto;
    }
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  max-width: 315px;
  margin-bottom: 75px;
  overflow-wrap: break-word;
  word-break: break-word;
  color: ${(props) => props?.theme?.colors?.textWhite};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      margin: 30px 0px 45px 0px;
      font-size: 24px;
      line-height: 30px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      transition: padding 0.3s ease-in-out;
      font-size: 12px;
      line-height: 16px;
      margin: 10px 0px 15px 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      transition: padding 0.3s ease-in-out;
      font-size: 18px;
      line-height: 21px;
      margin: 0px;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: column;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: row;
      align-items: start;
      gap: 6px;
      width: 100%;
    }
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: column;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: row;
    }
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
      flex-direction: row;
      height: 55px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      width: calc(100% / 3);
      gap: 1px;
    }
  }
`;

export const Circle = styled.div`
  width: 46.32px;
  height: 46.32px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  background-color: ${({ completed, type, theme }) =>
    completed
      ? type === AuthType.CLIENT
        ? theme?.colors?.clientRegistration?.Circle
        : theme?.colors?.userRegistration?.Circle
      : theme?.colors?.whiteOpacity30};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${({ completed, theme }) =>
    completed
      ? theme?.colors?.leftAuthContentBackground
      : theme?.colors?.textUncompletedStep};
  position: relative;

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      span {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

export const Line = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  height: 64px;
  width: 0px;
  border-left: 4px solid
    ${({ completed, type, theme }) =>
      completed
        ? type === AuthType.CLIENT
          ? theme?.colors?.clientRegistration?.Circle
          : theme?.colors?.userRegistration?.Circle
        : theme?.colors?.uncompletedRegistrationBackground};
  z-index: 1;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 15px;
      border-left: 4px solid
        ${({ completed, type, theme }) =>
          completed
            ? type === AuthType.CLIENT
              ? theme?.colors?.clientRegistration?.Circle
              : theme?.colors?.userRegistration?.Circle
            : theme?.colors?.uncompletedRegistrationBackground};
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-left: 0px;
      margin-right: 5px;
      height: 4px;
      width: 59px;
      border: 2.8px solid
        ${({ completed, type, theme }) =>
          completed
            ? type === AuthType.CLIENT
              ? theme?.colors?.clientRegistration?.Circle
              : theme?.colors?.userRegistration?.Circle
            : theme?.colors?.uncompletedRegistrationBackground};
    }
  }
`;

export const Label = styled.span`
  margin-left: 10px;
  color: ${({ completed, theme }) =>
    completed ? theme.colors.textWhite : theme.colors.textUncompletedStep};
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  text-align: left;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-left: 0px;
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
      font-size: 10px;
      line-height: 14px;
      margin-top: 3px;
    }
  }
`;
