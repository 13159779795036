import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTypography,
  CollapTableRow,
  CollapseStyled,
  CollapContentWrapper,
  ScrollableRows,
} from "./CollapTableBodyContainer.styled";
import { appointmentsTableConstants } from "constants/tableConstants/appointmentsTableConstants";
import dayjs from "dayjs";
import { TEXTVARIANT } from "constants/textConstants";
import tooltipConstants from "constants/tooltipConstants";
import { Tooltip } from "@mui/material";

export const CollapTableBodyContainer = (props) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowToggle = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const handleCheckChange = (nestedRow) => {
    setSelectedRow((prevSelectedRow) => {
      const isRowSelected = prevSelectedRow?.id === nestedRow.id;
      const newValue = isRowSelected ? null : nestedRow;
      props?.passSelectedTimeSlot(newValue);

      return isRowSelected ? null : nestedRow;
    });
  };
  useEffect(() => {
    setSelectedRow(null);
  }, [props?.rows]);
  return (
    <StyledTableBody>
      {props?.rows?.map((row) => (
        <React.Fragment key={row?.id}>
          <StyledTableRow onClick={() => handleRowToggle(row?.id)}>
            <StyledTableCell>
              <StyledTypography>
                {dayjs(row.date).format("DD.MM.YYYY")}
              </StyledTypography>
            </StyledTableCell>
          </StyledTableRow>

          <CollapseStyled
            in={expandedRows[row?.id]}
            timeout="auto"
            unmountOnExit
          >
            <ScrollableRows>
              <CollapContentWrapper>
                {row?.nestedRows?.map((nestedRow) => {
                  const isChecked = selectedRow?.id === nestedRow?.id;

                  return (
                    <CollapTableRow key={nestedRow.id} isChecked={isChecked}>
                      {appointmentsTableConstants?.map((col) => {
                        const cellData = col?.valueFormatter
                          ? col?.valueFormatter(nestedRow[col.field])
                          : nestedRow[col.field];

                        const cellRendered = col?.cellRenderer ? (
                          col?.cellRenderer({
                            data: cellData,
                            row: nestedRow,
                            checked: isChecked,
                            onCheck: () => handleCheckChange(nestedRow),
                          })
                        ) : cellData?.length >
                          props?.numberOfCharactersPerCell ? (
                          <Tooltip
                            title={cellData}
                            placement={tooltipConstants.TOP}
                          >
                            <StyledTypography variant={TEXTVARIANT.H5}>
                              {cellData.slice(
                                0,
                                props?.numberOfCharactersPerCell - 3
                              ) + "..."}
                            </StyledTypography>
                          </Tooltip>
                        ) : (
                          cellData
                        );

                        return (
                          <StyledTableCell key={col.field} width={col.width}>
                            {cellRendered}
                          </StyledTableCell>
                        );
                      })}
                    </CollapTableRow>
                  );
                })}
              </CollapContentWrapper>
            </ScrollableRows>
          </CollapseStyled>
        </React.Fragment>
      ))}
    </StyledTableBody>
  );
};

CollapTableBodyContainer.propTypes = {
  rows: PropTypes.any,
  theme: PropTypes.any,
  passSelectedTimeSlot: PropTypes.func,
  numberOfCharactersPerCell: PropTypes.number,
};
