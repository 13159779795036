import React from "react";
import PropTypes from "prop-types";
import ButtonStyled from "./Button.styled";
import { VARIANT } from "../../constants/buttonConstants";

const Button = (props) => {
  return (
    <ButtonStyled
      className={props?.className}
      name={props.name}
      variant={props.variant}
      disabled={props.disabled}
      hidden={props.hidden}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      type={props.type}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      bgColor={props.bgColor}
      bgColorHover={props.hoverColor}
      textColor={props.color}
      fontSize={props.fontSize}
      fontWeight={props?.fontWeight}
      padding={props.padding}
      borderRadius={props.borderRadius}
      textTransform={props.textTransform}
      isClient={props?.isClient}
      leftClientPageButton={props?.leftClientPageButton}
      borderColor={props?.borderColor}
      border={props?.border}
      disableRipple={props?.disableRipple}
      height={props?.height}
    >
      {props.value}
    </ButtonStyled>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  border: PropTypes.bool,
  borderColor: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  bgColor: PropTypes.string,
  leftClientPageButton: PropTypes.bool,
  bgColorHover: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  textTransform: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  type: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  isClient: PropTypes.bool,
  fontWeight: PropTypes.string,
  disableRipple: PropTypes.bool,
  height: PropTypes.string,
};

Button.defaultProps = {
  variant: VARIANT.TEXT,
  color: "black",
  fontSize: "20px",
  padding: "12px 32px 12px 32px",
  borderRadius: "12px",
  textTransform: "none",
};

export default Button;
