import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  gap: 20px;
  overflow-y: auto;
  padding: 20px;
  height: 100%;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 0px;
      gap: 12px;
      grid-template-columns: repeat(auto-fill, 165px);
      justify-content: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 0px;
      gap: 12px;
      grid-template-columns: repeat(auto-fill, 165px);
      justify-content: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 0px;
      gap: 12px;
      grid-template-columns: repeat(auto-fill, 165px);
      justify-content: center;
    }
  }
`;
