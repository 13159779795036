import React from "react";
import PropTypes from "prop-types";
import { REGISTER_STEP } from "constants/authConstants";
import {
  RightAuthContainer,
  ContentContainer,
  RegTitleContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import UserStepOne from "./UserSteps/UserStepOne";
import UserStepTwo from "./UserSteps/UserStepTwo";
import UserStepThree from "./UserSteps/UserStepThree";

const UserRegistrationContent = (props) => {
  return (
      <RightAuthContainer>
        <RegTitleContainer>
          {props.titleLabel}
        </RegTitleContainer>
        <ContentContainer>
          {props.currentStep==REGISTER_STEP.ONE && 
          <UserStepOne 
            currentStep={props.currentStep} 
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />}
          {props.currentStep==REGISTER_STEP.TWO && 
          <UserStepTwo 
            currentStep={props.currentStep} 
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />}
          {props.currentStep==REGISTER_STEP.THREE && 
          <UserStepThree 
            currentStep={props.currentStep} 
            onClickBackBtn={props.onClickBackBtn}
            onClickNextPageBtn={props.onClickNextPageBtn}
          />}
        </ContentContainer>
      </RightAuthContainer>
  );
};

UserRegistrationContent.propTypes = {
  label: PropTypes.string,
  currentStep: PropTypes.number,
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  titleLabel: PropTypes.string,
};

export default UserRegistrationContent;
