import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unstable_useBlocker } from "react-router-dom";
import { Table, TableRow } from "@mui/material";
import {
  NotificationListTableBody,
  NotificationRowContainer,
  NotificationStyledContainer,
  NotificationStyledTypography,
  StyledNavLink,
  StyledNotificationRow,
  StyledPagination,
  StyledTableCell,
} from "./NotificationTable.styled";
import { VARIANT } from "constants/buttonConstants";
import { NOTIFICATION_TYPES } from "constants/notificationTypeConstants";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow, format } from "date-fns";
import { enUS, srLatn } from "date-fns/locale";
import i18n from "i18nt";
import { setAppointmentRequestModal } from "features/modal/modalSlice";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from "features/user/usersApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { useNotifications } from "hooks/useNotifications";
import { markAllAsRead } from "features/notificationsSlice/notificationsSlice";

const NotificationTable = () => {
  const [readNotification] = useReadNotificationMutation();
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: data, refetch } = useGetNotificationsQuery(user?.id);
  const notifications = data?.data?.notifications;
  const [currentPage, setCurrentPage] = useState(1);
  useNotifications(refetch);

  const pageSize = 10;
  const totalPages = Math.max(Math.ceil(notifications?.length / pageSize), 1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, notifications?.length);
  const paginatedNotifications = notifications?.slice(startIndex, endIndex);

  useEffect(() => {
    if (notifications?.length > 0 && startIndex >= notifications?.length) {
      setCurrentPage(1);
    }
  }, [notifications?.length, startIndex]);

  unstable_useBlocker(() => {
    if (notifications.some((notification) => !notification.read)) {
      dispatch(markAllAsRead());
    }
  });

  const formatSentAt = (sentAt) => {
    const sentDate = new Date(sentAt);

    const localOffset = sentDate.getTimezoneOffset() * 60000;
    const localDate = new Date(sentDate.getTime() - localOffset);

    const now = new Date();

    const locales = {
      "en-US": enUS,
      "sr-Latn": srLatn,
    };
    const selectedLocale = locales[i18n.language];

    const differenceInMilliseconds = now - localDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    if (differenceInHours < 24) {
      const exactTime = format(localDate, "HH:mm", { locale: selectedLocale });
      const relativeTime = formatDistanceToNow(localDate, {
        addSuffix: true,
        locale: selectedLocale,
      });

      return `${exactTime} (${relativeTime})`;
    }

    return format(localDate, "dd.MM.yyyy", { locale: selectedLocale });
  };

  const markNotificationAsRead = async (notification) => {
    if (!notification?.readAt) {
      await readNotification({
        userId: notification?.recipientId,
        notificationId: notification?.id,
      });
    }
  };

  const renderNotificationContent = (notification) => {
    const isUnprocessed = notification?.updatedAtUtc === null;
    const isUnread = notification?.readAt === null;
    let content = (
      <NotificationRowContainer
        onClick={() => markNotificationAsRead(notification)}
        processed={!isUnprocessed}
        read={!isUnread}
      >
        {notification?.notificationText}
      </NotificationRowContainer>
    );

    if (isUnprocessed) {
      if (notification?.type === NOTIFICATION_TYPES.APPOINTMENT_REQUESTED.id) {
        return (
          <StyledNavLink
            onClick={() =>
              dispatch(
                setAppointmentRequestModal({
                  appointmentId: notification?.appointmentId,
                  notificationType: notification?.type,
                })
              )
            }
          >
            {content}
          </StyledNavLink>
        );
      }

      if (
        notification?.type === NOTIFICATION_TYPES.USER_WISHLIST_NOTIFICATION.id
      ) {
        return (
          <StyledNavLink
            to={replaceInRoute(PAGES.CLIENT.route, {
              clientId: notification?.clientId,
            })}
          >
            {content}
          </StyledNavLink>
        );
      }
    }

    return content;
  };

  return (
    <NotificationStyledContainer>
      <Table>
        <NotificationListTableBody>
          {paginatedNotifications?.map((notification, index) => (
            <TableRow key={index}>
              <StyledTableCell>
                <StyledNotificationRow>
                  <NotificationStyledTypography variant={VARIANT.BODY1}>
                    {t(
                      Object.values(NOTIFICATION_TYPES).find(
                        (type) => type.id === notification?.type
                      )?.name
                    )}
                  </NotificationStyledTypography>
                  <NotificationStyledTypography>
                    {formatSentAt(notification?.sentAt)}
                  </NotificationStyledTypography>
                </StyledNotificationRow>
                {renderNotificationContent(notification)}
              </StyledTableCell>
            </TableRow>
          ))}
        </NotificationListTableBody>
      </Table>

      <StyledPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </NotificationStyledContainer>
  );
};

export default NotificationTable;
