import React from "react";
import PropTypes from "prop-types";
import {
  LoginButton,
  LogInImageStyled,
  RegisterButton,
  RegisterLoginOptionsContainer,
} from "./RegisterLoginOptions.styled";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";

const RegisterLoginOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const onClickRegister = () => {
    navigate(PAGES.MIDDLEPAGE.route);
  };

  const onClickLogin = () => {
    navigate(PAGES.LOGIN.route);
  };

  return (
    <>
      {!isMobile ? (
        <RegisterLoginOptionsContainer>
          <RegisterButton
            value={t("register.registerTitle")}
            onClick={onClickRegister}
          />
          <LoginButton value={t("login.logInTitle")} onClick={onClickLogin} />
        </RegisterLoginOptionsContainer>
      ) : 
      (<LogInImageStyled onClick={onClickLogin} />)}
    </>
  );
};

RegisterLoginOptions.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default RegisterLoginOptions;
