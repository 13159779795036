import { useDeclineAppointmentRequestMutation } from "features/appointmentSlice/appointmentApiSlice";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AcceptButton,
  BoldText,
  CancelButton,
  DeclineButton,
  Divider,
  Label,
  Modal,
  PriceLabel,
  Status,
  Title,
  UpperContainer,
} from "./AppointmentRequestModal.styled";
import React from "react";
import { useDispatch } from "react-redux";
import {
  setAcceptAppointmentRequestModal,
  unsetShownModal,
} from "features/modal/modalSlice";
import { useGetAppointmentByIdQuery } from "features/appointmentSlice/appointmentApiSlice";
import toastNotificationType from "constants/toastNotificationType";
import { makeToastMessage } from "util/toastMessage";
import { appointmentStatuses } from "constants/filters/statusConstants";
import { LowerContainerWrapper } from "components/SuperAdmin/LicenseDeleteModal/LicenseDeleteModal.styled";
import { NOTIFICATION_TYPES } from "constants/notificationTypeConstants";
import { CloseIcon } from "components/ScheduleEditor/SetWorkingHoursModal/SetWorkingHoursModal.styled";

const AppointmentRequestModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: appointment } = useGetAppointmentByIdQuery(
    props?.appointmentId
  );
  const [declineAppointmentRequest] = useDeclineAppointmentRequestMutation();

  const handleDecline = async () => {
    const response = await declineAppointmentRequest(props?.appointmentId);
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("appointments.declineAppointmentRequestResponse"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  const handleAccept = async (appointment) => {
    dispatch(unsetShownModal());
    dispatch(setAcceptAppointmentRequestModal(appointment));
  };

  const getAppointmentStatusText = (statusId) => {
    const status = appointmentStatuses.find((status) => status.id === statusId);
    return status ? status.name : "";
  };

  const hasAttributesOrResources =
    (appointment?.data?.appointment?.attributes &&
      appointment?.data?.appointment?.length > 0) ||
    (appointment?.data?.appointment?.resources &&
      appointment?.data?.appointment?.resources.some(
        (resource) => resource?.type === 1
      ));

  const calculateFullPrice = () => {
    const basePrice = parseFloat(
      appointment?.data?.appointment?.clientService?.price
    );
    const additionalPrice =
      appointment?.data?.appointment?.chosenAttributes?.reduce(
        (total, attribute) => {
          return total + (parseFloat(attribute.price) || 0);
        },
        0
      );
    return basePrice + additionalPrice;
  };

  return (
    <Modal>
      <UpperContainer maxHeight={hasAttributesOrResources}>
        <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        <Title>
          {t(
            Object.values(NOTIFICATION_TYPES).find(
              (type) => type.id === props?.notificationType
            ).name
          )}
          <Status statusId={appointment?.data?.appointment?.appointmentStatus}>
            {t(
              getAppointmentStatusText(
                appointment?.data?.appointment?.appointmentStatus
              )
            )}
          </Status>
        </Title>
        <Label>
          {t("calendar.start")}
          <BoldText>
            {new Date(
              appointment?.data?.appointment.fromTimeUtc
            ).toLocaleString()}
          </BoldText>
        </Label>
        <Label>
          {t("calendar.end")}
          <BoldText>
            {new Date(
              appointment?.data?.appointment.toTimeUtc
            ).toLocaleString()}
          </BoldText>
        </Label>
        {appointment?.data?.appointment?.chosenAttributes &&
          appointment?.data?.appointment?.chosenAttributes.length > 0 && (
            <>
              <Label>
                {t("calendar.price")}
                <BoldText>
                  {appointment?.data?.appointment?.clientService?.price} din
                </BoldText>
              </Label>
              <Label>
                {t("calendar.includedAttributes")}
                <ul>
                  {appointment?.data?.appointment?.chosenAttributes.map(
                    (attribute, index) => (
                      <li key={index}>
                        <BoldText>{attribute.name}</BoldText>
                        {attribute.price && (
                          <span>
                            {" "}
                            ({t("calendar.additionalPrice")} {attribute.price}{" "}
                            din)
                          </span>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </Label>
            </>
          )}
        {appointment?.data?.appointment?.allocatedResources &&
          appointment?.data?.appointment?.allocatedResources.some(
            (resource) => resource?.resource?.type === 1
          ) && (
            <Label>
              {t("calendar.includedResources")}
              <ul>
                {appointment?.data?.appointment?.allocatedResources.map(
                  (resource, index) => {
                    if (resource?.resource?.type === 1) {
                      return (
                        <li key={index}>
                          <BoldText>{resource?.resource?.name}</BoldText>
                        </li>
                      );
                    }
                    return null;
                  }
                )}
              </ul>
            </Label>
          )}
        <PriceLabel>
          {t("calendar.fullPrice")}
          <BoldText>{calculateFullPrice()} din</BoldText>
        </PriceLabel>
        <>
          <Divider />
          <Label>
            {t("calendar.clientName")}
            <BoldText>
              {appointment?.data?.appointment?.user?.firstName}{" "}
              {appointment?.data?.appointment?.user?.lastName}
            </BoldText>
          </Label>
          {appointment?.data?.appointment?.user?.phoneNumber && (
            <Label>
              {t("calendar.phone")}{" "}
              <BoldText>
                {appointment?.data?.appointment?.user?.phoneNumber}
              </BoldText>
            </Label>
          )}
        </>

        {appointment?.data?.appointment?.note && (
          <Label>
            {t("calendar.note")}
            <BoldText>{appointment?.data?.appointment?.note}</BoldText>
          </Label>
        )}
      </UpperContainer>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.quit")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <DeclineButton value={t("common.decline")} onClick={handleDecline} />
        <AcceptButton
          value={t("common.accept")}
          onClick={() => handleAccept(appointment?.data?.appointment)}
        />
      </LowerContainerWrapper>
    </Modal>
  );
};

AppointmentRequestModal.propTypes = {
  appointmentId: PropTypes.string,
  notificationType: PropTypes.number,
};

export default AppointmentRequestModal;
