import React from "react";
import PropTypes from "prop-types";
import {
  ProgressContainer,
  Title,
  Step,
  Circle,
  Line,
  Label,
  CheckIcon,
  LogImg,
  ContentContainer,
  Container,
  ProgressBar,
} from "./LeftAuthContent.styled";
import AuthType from "constants/authType";
import { LogoReg } from "components/Header/Header.styled";
import { StyledLink } from "components/Sidebar/Sidebar.styled";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";

const LeftAuthContent = (props) => {
  if (props.type === AuthType.LOGIN) {
    return (
      <Container>
        <LogImg />
      </Container>
    );
  }
  const { isMobile } = useDevice();

  return (
    <ProgressContainer>
      <StyledLink to={PAGES.BASE.route}>
        <LogoReg />
      </StyledLink>
      <Title>{props.title}</Title>
      <ProgressBar>
        {props.steps.map((step, index) => (
          <Step key={index}>
            {!isMobile ? (
              <>
                <ContentContainer>
                  <Circle type={props.type} completed={step.completed}>
                    <span>{step.completed ? <CheckIcon /> : index + 1}</span>
                  </Circle>
                  <Label completed={step.completed}>{step.label}</Label>
                </ContentContainer>
                {index < props.steps.length - 1 && (
                  <Line type={props.type} completed={step.completed} />
                )}
              </>
            ) : (
              <>
                <ContentContainer>
                  <Circle type={props.type} completed={step.completed}>
                    <span>{step.completed ? <CheckIcon /> : index + 1}</span>
                  </Circle>
                  {index < props.steps.length - 1 && (
                    <Line type={props.type} completed={step.completed} />
                  )}
                </ContentContainer>
                <Label completed={step.completed}>{step.label}</Label>
              </>
            )}
          </Step>
        ))}
      </ProgressBar>
    </ProgressContainer>
  );
};

LeftAuthContent.propTypes = {
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  steps: PropTypes.array,
  title: PropTypes.string,
};

export default LeftAuthContent;
