import Button from "components/Button/Button";
import styled from "styled-components";

export const GoogleButtonContainer = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: ${(props) => props?.theme?.colors?.googleButton};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.googleButtonHover};
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      height: 40px;
      white-space: nowrap;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }
  }
`;
