import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "constants/pages";
import { NavLink } from "react-router-dom";
import { REGISTER_STEP } from "constants/authConstants";
import {
  LowerContainer,
  NextBackBtnsContainer,
  BottomTextContainer,
  FirstText,
  LogRegLinkStyled,
} from "components/RightAuthContent/RightAuthContent.styled";
import { useTranslation } from "react-i18next";
import NextButton from "components/RightAuthContent/Buttons/NextButton/NextButton";
import BackButton from "components/RightAuthContent/Buttons/BackButton/BackButton";

const LowerRegContent = (props) => {
  const { t } = useTranslation();

  return (
    <LowerContainer gap={props?.gap}>
      <NextBackBtnsContainer
        twoButtons={props.currentStep != REGISTER_STEP.ONE}
      >
        {props.currentStep > REGISTER_STEP.ONE && (
          <BackButton onClick={props.onClickBackBtn} />
        )}
        <NextButton
          lastStep={props.lastStep}
          disabled={props.disabled}
          onClick={props.onClickNextBtn}
          colorPalet={props.colorPalet}
        />
      </NextBackBtnsContainer>
      <BottomTextContainer>
        <FirstText>{t("register.alreadyHaveAccount")}</FirstText>
        <LogRegLinkStyled
          to={PAGES.LOGIN.route}
          component={NavLink}
          color={props.colorPalet.buttonColor}
        >
          {t("login.logIn")}
        </LogRegLinkStyled>
      </BottomTextContainer>
    </LowerContainer>
  );
};

LowerRegContent.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  theme: PropTypes.any,
  currentStep: PropTypes.number,
  onClickNextBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  colorPalet: PropTypes.object,
  lastStep: PropTypes.bool,
  gap: PropTypes.bool,
};

LowerRegContent.defaultProps = {
  gap: false,
};

export default LowerRegContent;
