import styled from "styled-components";
import { Box } from "@mui/material";
import { ReactComponent as LogoImage } from "../../assets/images/svg/sidebar-logo.svg";
import { ReactComponent as MiniLogoImage } from "../../assets/images/svg/sidebar-collapsed-logo.svg";
import { ReactComponent as MobileSidebarImage } from "../../assets/images/svg/mobile-sidebar-collapsed.svg";
import { ReactComponent as MobileLogoImage } from "../../assets/images/svg/header-logo.svg";
import { ReactComponent as LeftArrowImg } from "../../assets/images/svg/double-left-arrow.svg";
import { ReactComponent as RightArrowImg } from "../../assets/images/svg/double-right-arrow.svg";
import { hexToRGB } from "util/colorHelper";
import { Link } from "react-router-dom";

export const LogoContainer = styled(LogoImage)`
  width: 150px;
  height: auto;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100px;
      height: 32px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 130px;
      margin-left: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 120px;
      height: 32px;
      margin-left: 0px;
    }
  }
`;

export const CollapsedLogoContainer = styled(MiniLogoImage)`
  width: 90px;
  height: 40px;
  margin: auto;
  cursor: pointer;
`;

export const MobileCollapsedLogoContainer = styled(MobileSidebarImage)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const MobileLogoContainer = styled(MobileLogoImage)`
  width: 85px;
  height: 50px;
  cursor: pointer;
  padding-bottom: 20px;
`;

export const StyledLink = styled(Link)`
  margin-bottom: 0;
  display: flex;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-top: 8px;
    }
  }
`;

export const SidebarContainer = styled(Box)`
  position: fixed;
  width: ${({ collapsed, isMobile }) =>
    collapsed ? "95px" : isMobile ? "0px" : "300px"};
  transition: width 0.3s ease-in-out;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  gap: 64px;
  border-radius: 24px;
  box-shadow: 5px 5px 10px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  padding: ${({ collapsed }) =>
    collapsed ? "32px 0px 32px 0px" : "32px 0px 32px 25px"};
  height: calc(100% - 40px);
  z-index: 1000;
  transform: translateX(0);

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: ${({ collapsed }) => (collapsed ? "95px" : "258px")};
      transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
      margin-right: 0px;
      margin-left: -16px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: ${({ collapsed }) => (collapsed ? "0px" : "240px")};
      max-width: 258px;
      transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
      height: 100%;
      height: auto;
      border-radius: 0;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      gap: 15px;
      margin-top: -20px;
      margin-bottom: 0px;
      ${({ collapsed }) => collapsed && `
      & > * {
        display: none;
      }
    `}
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: ${({ collapsed }) => (collapsed ? "0px" : "258px")};
      max-width: 258px;
      transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
      height: 100%;
      border-radius: 0;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      gap: 48px;
      margin-top: -16px;
      margin-left: -16px;
      ${({ collapsed }) => collapsed && `
      & > * {
        display: none;
      }
    `}
    }
  }
`;

export const MobileSidebarContainer = styled(Box)`
  max-width: 130px;
  transition: transform 0.8s ease-in-out;
  transform: translateX(0);
  display: flex;
  height: 32px;
  gap: 12px;
`;

export const TabsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: normal;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 4px;
    }
  }
`;

export const IndividualTab = styled(Box)`
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme?.colors?.white : "transparent"};
  color: ${({ selected, theme }) =>
    selected ? theme?.colors?.white : theme?.colors?.black};
  &:hover {
    background-color: ${(selected, theme) =>
      selected ? theme?.colors?.white : theme?.colors?.hoverDarkBlue};
  }
  width: 100%;
`;

export const ArrowImage = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: right;
  padding-right: ${({ showArrow }) => (showArrow ? "16px" : "0px")};
  margin-right: 16px;
  align-items: flex-end;
`;

export const LeftArrowImage = styled(LeftArrowImg)`
  display: ${({ hideArrow }) => (hideArrow ? "none" : "block")};
  cursor: pointer;
`;

export const RightArrowImage = styled(RightArrowImg)`
  visibility: ${({ showArrow }) => (showArrow ? "visible" : "hidden")};
  cursor: pointer;
`;
