export const NOTIFICATION_TYPES = {
  VERIFICATION_EMAIL: { id: 0, name: "notifications.types.verificationEmail" },
  APPOINTMENT_SCHEDULED: {
    id: 1,
    name: "notifications.types.appointmentScheduled",
  },
  APPOINTMENT_CANCELED: {
    id: 2,
    name: "notifications.types.appointmentCanceled",
  },
  CLIENT_STATUS_UPDATED: {
    id: 3,
    name: "notifications.types.clientStatusUpdated",
  },
  LICENSE_UPDATED: { id: 4, name: "notifications.types.licenseUpdated" },
  LICENSE_EXPIRED: { id: 5, name: "notifications.types.licenseExpired" },
  APPOINTMENT_START: { id: 6, name: "notifications.types.appointmentStart" },
  APPOINTMENT_SCHEDULED_NOTIFICATION: {
    id: 7,
    name: "notifications.types.appointmentScheduledNotification",
  },
  APPOINTMENT_CANCELED_NOTIFICATION: {
    id: 8,
    name: "notifications.types.appointmentCanceledNotification",
  },
  LICENSE_UPDATED_NOTIFICATION: {
    id: 9,
    name: "notifications.types.licenseUpdatedNotification",
  },
  LICENSE_PLUS_DEACTIVATION_UPDATED_NOTIFICATION: {
    id: 10,
    name: "notifications.types.licensePlusDeactivationUpdatedNotification",
  },
  LICENSE_ADDED_NOTIFICATION: {
    id: 11,
    name: "notifications.types.licenseAddedNotification",
  },
  LICENSE_DELETED_NOTIFICATION: {
    id: 12,
    name: "notifications.types.licenseDeletedNotification",
  },
  ACCOUNT_STATUS_NOTIFICATION: {
    id: 13,
    name: "notifications.types.accountStatusNotification",
  },
  APPOINTMENT_REQUESTED: {
    id: 14,
    name: "notifications.types.appointmentRequested",
  },
  APPOINTMENT_REQUEST_ACCEPTED: {
    id: 15,
    name: "notifications.types.appointmentRequestAccepted",
  },
  APPOINTMENT_REQUEST_DECLINED: {
    id: 16,
    name: "notifications.types.appointmentRequestDeclined",
  },
  USER_WISHLIST_NOTIFICATION: {
    id: 17,
    name: "notifications.types.userWishlistNotification",
  },
};
