import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  Modal,
  UpperContainer,
} from "./CalendarResourcesInformationModal.styled";
import {
  ActionButton,
  BoldText,
  CancelActionButton,
  Label,
  LowerContainer,
  Status,
  Title,
} from "../CalendarInformationAndCancellationModal/CalendarInformationAndCancellationModal.styled";
import { useCancelAppointmentByClientMutation } from "features/appointmentSlice/appointmentApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { appointmentStatuses } from "constants/filters/statusConstants";

const CalendarResourcesInformationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancelAppointmentByClient] = useCancelAppointmentByClientMutation();

  const handleCancel = async () => {
    var response = await cancelAppointmentByClient({
      appointmentId: props?.resource?.appointmentId,
      clientId: props?.resource?.clientId,
    });

    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("appointments.cancelReservedResourceResponse"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  const cancel = () => {
    dispatch(unsetShownModal());
  };

  const isResourceCancelled = [4].includes(props.resource?.appointmentStatus);

  const getAppointmentStatusText = (statusId) => {
    const status = appointmentStatuses.find((status) => status.id === statusId);
    return status ? status.name : "";
  };

  return (
    <Modal>
      <UpperContainer>
        <Title>
          {t("calendar.info")}
          <Status statusId={props?.resource?.appointmentStatus}>
            {t(getAppointmentStatusText(props?.resource?.appointmentStatus))}
          </Status>
        </Title>
        <Label>
          {t("calendar.resevedFrom")}
          <BoldText>{new Date(props.resource.start).toLocaleString()}</BoldText>
        </Label>
        <Label>
          {t("calendar.reservedTo")}
          <BoldText>{new Date(props.resource.end).toLocaleString()}</BoldText>
        </Label>
        <Label>
          {t("calendar.resevedResources")}
          <ul>
            {props.resource.resources.map((resource, index) => {
              return (
                <li key={index}>
                  <BoldText>{resource.name}</BoldText>
                </li>
              );
            })}
          </ul>
        </Label>
        {props?.resource?.note && (
          <Label>
            {t("calendar.note")}
            <BoldText>{props.resource.note}</BoldText>
          </Label>
        )}
      </UpperContainer>
      <LowerContainer>
        <CancelActionButton
          value={t("common.close")}
          onClick={() => cancel()}
        />
        <ActionButton
          value={t("calendar.cancelReservation")}
          onClick={handleCancel}
          disabled={isResourceCancelled}
        />
      </LowerContainer>
    </Modal>
  );
};

CalendarResourcesInformationModal.propTypes = {
  resource: PropTypes.any,
};

export default CalendarResourcesInformationModal;
