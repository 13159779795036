import styled from "styled-components";
import { Select, MenuItem } from "@mui/material";
import { ReactComponent as DownArrow } from "assets/images/svg/down-arrow.svg";

export const CustomSelectFieldStyled = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 16px;
    border-width: 0px;
  }

  width: 40px;
  height: 40px;
  margin-left: 20px;
  background-color: ${({ theme }) => theme?.colors?.darkBlue};
  border-radius: 16px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 24px;
      height: 24px;
      margin-left: 0px;
      border-radius: 6px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      margin-left: 10px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 24px;
      height: 24px;
      margin-left: 0px;
      border-radius: 6px;
    }
  }

  & .MuiInputBase-input {
    padding-left: 15%;
    color: ${({ theme }) => theme?.colors?.white};
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    @media (max-width: 600px) and (orientation: portrait)
    {
      font-size: 12px;
      margin-bottom: 1px;
      margin-left: 1px;
    }

    @media (max-height: 600px) and (orientation: landscape)
    {
      font-size: 12px;
      margin-bottom: 1px;
      margin-left: 1px;
    }
  }

  & .MuiSelect-icon {
    top: 45%;
    right: 5px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
`;

export const DownArrowStyled = styled(DownArrow)`
  width: 10px;
  height: 10px;
  fill: ${(props) => props?.theme?.colors?.white};
  stroke: ${(props) => props?.theme?.colors?.white};
`;

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      maxWidth: 100,
    },
  },
};

export default CustomSelectFieldStyled;
