import styled from "styled-components";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 0px;
    }
  }
`;

export const ItemContainer = styled.div`
  flex: 0;
  height: 24px;
  width: 98px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      display: flex;
      align-items: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      display: flex;
      align-items: center;
      width: 90px;
    }
  }
`;

export const DayLabelContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
  width: 98px;
`;

export const TimeContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textColor2};
  width: 98px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const LabelNonWorkingDay = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.errorColor};
  width: 98px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
