import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  ActionButton,
  CancelActionButton,
  Title,
  LowerContainer,
  Modal,
  UpperContainer,
  Label,
  BoldText,
  Status,
  Divider,
  PriceLabel,
} from "./CalendarInformationAndCancellationModal.styled";
import {
  useCancelAppointmentByClientMutation,
  useCancelAppointmentMutation,
} from "features/appointmentSlice/appointmentApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { appointmentStatuses } from "constants/filters/statusConstants";

const CalendarInformationAndCancellationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancelAppointment] = useCancelAppointmentMutation();
  const [cancelAppointmentByClient] = useCancelAppointmentByClientMutation();

  const handleCancel = async () => {
    var response;
    if (props?.isUser) {
      response = await cancelAppointment({
        appointmentId: props?.appointment?.appointmentId,
        userId: props?.appointment?.userId,
      });
    } else {
      response = await cancelAppointmentByClient({
        appointmentId: props?.appointment?.appointmentId,
        clientId: props?.appointment?.clientId,
      });
    }
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("appointments.cancelAppointmentResponse"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  const cancel = () => {
    dispatch(unsetShownModal());
  };

  const isAppointmentCancelledOrDone = [1, 2].includes(
    props.appointment?.appointmentStatus
  );

  const getAppointmentStatusText = (statusId) => {
    const status = appointmentStatuses.find((status) => status.id === statusId);
    return status ? status.name : "";
  };

  const hasAttributesOrResources =
    (props?.appointment?.attributes &&
      props?.appointment?.attributes?.length > 0) ||
    (props?.appointment?.resources &&
      props?.appointment?.resources.some((resource) => resource?.type === 1));

  const calculateFullPrice = () => {
    const basePrice = parseFloat(props.appointment.price);
    const additionalPrice = props.appointment.attributes?.reduce(
      (total, attribute) => {
        return total + (parseFloat(attribute.price) || 0);
      },
      0
    );
    return basePrice + additionalPrice;
  };

  return (
    <Modal>
      <UpperContainer maxHeight={hasAttributesOrResources}>
        <Title>
          {props.appointment.title}
          <Status statusId={props?.appointment?.appointmentStatus}>
            {t(getAppointmentStatusText(props?.appointment?.appointmentStatus))}
          </Status>
        </Title>
        <Label>
          {t("calendar.start")}
          <BoldText>
            {new Date(props.appointment.start).toLocaleString()}
          </BoldText>
        </Label>
        <Label>
          {t("calendar.end")}
          <BoldText>
            {new Date(props.appointment.end).toLocaleString()}
          </BoldText>
        </Label>
        {props.appointment.attributes &&
          props.appointment.attributes.length > 0 && (
            <>
              <Label>
                {t("calendar.price")}
                <BoldText>{props.appointment.price} din</BoldText>
              </Label>
              <Label>
                {t("calendar.includedAttributes")}
                <ul>
                  {props.appointment.attributes.map((attribute, index) => (
                    <li key={index}>
                      <BoldText>{attribute.name}</BoldText>
                      {attribute.price && (
                        <span>
                          {" "}
                          ({t("calendar.additionalPrice")} {attribute.price}{" "}
                          din)
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </Label>
            </>
          )}
        {props.appointment.resources &&
          props.appointment.resources.some(
            (resource) => resource.type === 1
          ) && (
            <Label>
              {t("calendar.includedResources")}
              <ul>
                {props.appointment.resources.map((resource, index) => {
                  if (resource.type === 1) {
                    return (
                      <li key={index}>
                        <BoldText>{resource.name}</BoldText>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </Label>
          )}
        <PriceLabel>
          {t("calendar.fullPrice")}
          <BoldText>{calculateFullPrice()} din</BoldText>
        </PriceLabel>
        {!props?.isUser && (
          <>
            <Divider />
            <Label>
              {t("calendar.clientName")}
              <BoldText>
                {props.appointment.userFirstName}{" "}
                {props.appointment.userLastName}
              </BoldText>
            </Label>
            {props.appointment.userPhoneNumber && (
              <Label>
                {t("calendar.phone")}{" "}
                <BoldText>{props.appointment.userPhoneNumber}</BoldText>
              </Label>
            )}
          </>
        )}
        {props?.appointment?.note && (
          <Label>
            {t("calendar.note")}
            <BoldText>{props.appointment.note}</BoldText>
          </Label>
        )}
      </UpperContainer>
      <LowerContainer>
        <CancelActionButton
          value={t("common.close")}
          onClick={() => cancel()}
        />
        <ActionButton
          value={t("calendar.cancel")}
          onClick={handleCancel}
          disabled={isAppointmentCancelledOrDone}
        />
      </LowerContainer>
    </Modal>
  );
};

CalendarInformationAndCancellationModal.propTypes = {
  appointment: PropTypes.any,
  isUser: PropTypes.bool,
};

export default CalendarInformationAndCancellationModal;
