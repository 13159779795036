import styled from "styled-components";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";
import { ImageContainer } from "components/ImageGallery/ImageGallery.styled";

export const GalleryContentContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      align-items: center;
      justify-content: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      align-items: center;
      justify-content: center;
    }
  }
`;

export const LeftArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ImagesContainerWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      max-width: 269px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      max-width: 269px;
    }
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ImageContainerStyled = styled(ImageContainer)`
  &:hover img {
    filter: brightness(100%);
  }
`;

export const RightArrowContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LeftArrowStyled = styled(LeftArrow)``;

export const RightArrowStyled = styled(RightArrow)``;

export const ArrowButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
