import { Typography } from "@mui/material";
import Button from "components/Button/Button";
import styled from "styled-components";
import { statusColors } from "themes/secondaryTheme/secondaryThemeColors";
import { hexToRGB } from "util/colorHelper";

export const Modal = styled.div`
  width: 460px;
  max-height: 700px;    
  box-sizing: border-box;
  overflow: hidden;   
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-bottom: none;
  position: relative;
  background-color: ${(props) => props?.theme?.colors?.white};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: fit-content;
  overflow: auto; 
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
`;

export const Status = styled(Typography)`
  background-color: ${(props) => statusColors[props?.statusId]};
  color: ${(props) => props.theme?.colors?.white};
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 30px;
  margin-left: 20px;
  display: inline-block;
`;

export const Label = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 16px;
`;

export const PriceLabel = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  background-color: ${(props) => props?.theme?.colors?.fieldBorderColor};
  font-size: 16px;
  border-radius: 30px;
  padding: 5px 10px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props?.theme?.colors?.white};
  justify-content: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px;
  width: 100%;
  height: 82px;
  padding: 25px 30px;
  gap: 24px;
`;

export const ActionButton = styled(Button)`
  height: 33px;
  width: 145px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => (props.disabled ? props.theme?.colors?.disabledBackground : props.theme?.colors?.darkBlue)};
  color: ${(props) => props.theme?.colors?.white};
  border-radius: 12px;
  text-transform: none;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  color: ${(props) => props.theme?.colors?.black};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-radius: 12px;
  &:hover {
    background-color: ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  }
`;

export const Divider = styled.div`
border-top: 1px solid ${(props) => props.theme?.colors?.fieldBorderColor};;
margin: 20px 0px;
`;