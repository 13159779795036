import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const MainContainer = styled.div`
  display: flex;
  padding: 16px;
  gap: 20px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: column;
      padding: 0px;
      align-items: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      padding: 0px;
      align-items: center;
    }
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  gap: 40px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 30%;


  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 70%;
      flex-direction: column;
      gap: 0px;
      align-items: flex-end;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      max-width: 390px;
      flex-direction: column;
      gap: 0px;
      align-items: flex-end;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
    }
  }
`;

export const GalleryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
  height: 275px;
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px 24px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
  height: 796.6px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: fit-content;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: fit-content;
    }
  }
`;

export const ClientAdminCheckContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
