import React from "react";
import PropTypes from "prop-types";
import {
  InputFieldContainer,
  InputFieldsContainer,
  OrDividerGoogleBtnContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import CountryField from "components/RegistrationContent/InputFields/CountryField";
import CityField from "components/RegistrationContent/InputFields/CityField";
import AddressField from "components/RegistrationContent/InputFields/AddressField";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { UserStepTwoValidation } from "validations/registerUserValidation";
import { useDispatch, useSelector } from "react-redux";
import { useRequestEmailVerificationCodeMutation } from "features/register/userRegisterApiSlice";
import { userColors } from "themes/secondaryTheme/secondaryThemeColors";
import {
  setAttributesStepTwo,
  selectUserAttributesStepTwo,
  selectUserAttributesStepOne,
} from "features/register/userRegisterSlice";
import useDevice from "hooks/useDevice";
import OrDivider from "components/OrDivider/OrDivider";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";

const UserStepTwo = (props) => {
  const dispatch = useDispatch();
  const [sendEmail] = useRequestEmailVerificationCodeMutation();
  const { isMobile } = useDevice();

  const userDataStepTwo = useSelector(selectUserAttributesStepTwo);
  const userDataStepOne = useSelector(selectUserAttributesStepOne);

  const reverseMappedData = {
    country: userDataStepTwo?.country,
    city: userDataStepTwo?.city,
    address: userDataStepTwo?.address,
  };

  const handleSubmit = async () => {
    const data = {
      country: formik.values.country,
      city: formik.values.city,
      address: formik.values.address,
    };

    dispatch(setAttributesStepTwo(data));

    const response = await sendEmail({
      email: userDataStepOne?.email,
    });

    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          default:
            alert(error.message);
            break;
        }
      });
    } else {
      props.onClickNextPageBtn();
    }
  };

  const handleBack = () => {
    dispatch(setAttributesStepTwo(formik.values));
    props.onClickBackBtn();
  };

  const formik = useFormik({
    initialValues: {
      ...(reverseMappedData ?? null),
    },
    validationSchema: UserStepTwoValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleGoogle = () => {};
  
  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <InputFieldContainer twoInputs>
            <CountryField formik={formik} required />
            <CityField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer twoInputs={!isMobile}>
            <AddressField formik={formik} required />
          </InputFieldContainer>
        </InputFieldsContainer>
        {isMobile ? (
          <OrDividerGoogleBtnContainer>
            <OrDivider />
            <GoogleButton onClick={handleGoogle} />
          </OrDividerGoogleBtnContainer>
        ) : null}
      </UpperContainer>
      <LowerRegContent
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={handleBack}
        currentStep={props.currentStep}
        colorPalet={userColors}
        gap={true}
      />
    </>
  );
};

UserStepTwo.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
};

export default UserStepTwo;
