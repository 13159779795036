import React from "react";
import PropTypes from "prop-types";
import AuthType from "constants/authType";
import LogInContent from "components/LogInContent/LogInContent";
import ClientRegistrationContent from "components/RegistrationContent/ClientRegistrationContent/ClientRegistrationContent";
import UserRegistrationContent from "components/RegistrationContent/UserRegistrationContent/UserRegistrationContent";

const RightAuthContent = (props) => {
  return (
    <>
      {props.type == AuthType.LOGIN && <LogInContent />} 
      {props.type == AuthType.CLIENT &&
      <ClientRegistrationContent
        label={props.label}
        titleLabel={props.titleLabel[props?.currentStep]?.titleLabel}
        currentStep={props.currentStep}
        onClickNextPageBtn={props.onClickNextPageBtn}
        onClickBackBtn={props.onClickBackBtn}
        type={props.type}
      />}
      {props.type == AuthType.USER && 
      <UserRegistrationContent
        label={props.label}
        titleLabel={props.titleLabel[props?.currentStep]?.titleLabel}
        currentStep={props.currentStep}
        onClickNextPageBtn={props.onClickNextPageBtn}
        onClickBackBtn={props.onClickBackBtn}
        type={props.type}
      />}
    </>
  );
}

RightAuthContent.propTypes = {
  label: PropTypes.string,
  currentStep: PropTypes.number,
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  theme: PropTypes.any,
  type: PropTypes.string.isRequired,
  titleLabel: PropTypes.any,
  
};

export default RightAuthContent;
