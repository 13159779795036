import Button from "components/Button/Button";
import styled from "styled-components";

export const NextTablePageContainer = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 6px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
  }
`;
