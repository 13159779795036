import styled from "styled-components";
import { ReactComponent as StarImage } from "assets/images/svg/star.svg";

export const SubscribeButtonStyled = styled.div`
  background-color: ${(props) =>
    props?.isClientPanel
      ? props?.theme?.colors?.backgroundColor
      : props?.theme?.colors?.darkBlue};
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1000;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 20px;
      height: 20px;
      top: 10%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 20px;
      height: 20px;
      top: 10%;
    }
  }
`;

export const StarImageStyled = styled(StarImage)`
  cursor: pointer;
  & path {
    fill: ${(props) =>
      props?.isClientPanel
        ? props?.isSubscribed
          ? props?.theme?.colors?.starColor
          : props?.theme?.colors?.text3
        : props?.theme?.colors?.starColor};
  }
  :hover {
    & path {
      /* fill: ${(props) =>
        props?.isClientPanel
          ? props?.isSubscribed
            ? props?.theme?.colors?.starColor
            : props?.theme?.colors?.text3
          : props?.theme?.colors?.starColor}; */
    }
  }
`;
