import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerOverlay = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  z-index: 1003;
`;

export const Spinner = styled.div`
  width: ${(props) => (props?.halfSize ? "25px" : "50px")};
  height: ${(props) => (props?.halfSize ? "25px" : "50px")};
  border: 5px solid ${(props) => props?.theme?.colors?.darkBlue};
  border-top: 5px solid white;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
