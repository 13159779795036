export const hexToRGB = (hex, opacity) => {
  var r = parseInt(hex?.slice(1, 3), 16),
    g = parseInt(hex?.slice(3, 5), 16),
    b = parseInt(hex?.slice(5, 7), 16);

  if (typeof opacity === "number") {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  
  let isDark = true;
  let attempts = 0;
  const maxAttempts = 100;

  while (isDark && attempts < maxAttempts) {
    color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;

    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    isDark = luminance < 128;
    
    attempts++;
  }
  return color;
};

