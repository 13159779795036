export const TOOLBAR = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
  STRIKE: "strike",
  ALIGN: "align",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify",
  ORDERED: "ordered",
  BULLET: "bullet",
  LINK: "link",
  LIST: "list",
};

export const FORMATS = {
  SIZE: "size",
  FONT: "font",
  COLOR: "color",
  BACKGROUND: "background",
};
