import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import MyCalendar from "components/ClientEditTab/ClientContentTab/TabContent/Calendar/CalendarComponent/CalendarComponent";
import FilterContainer from "components/Filter/FilterContainer";
import { InputFieldContainer } from "components/Filter/FilterContainer.styled";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import { appointmentStatuses } from "constants/filters/statusConstants";
import {
  useGetAppointmentsForClientEmployeeQuery,
  useGetPaginatedAppointmentsForUserQuery,
  useGetUserWishListQuery,
} from "features/appointmentSlice/appointmentApiSlice";
import { selectCurrentRoles, selectCurrentUser } from "features/auth/authSlice";
import { getValueToRemove, removeFilter } from "util/filterHelper";
import { randomIdGenerator } from "util/randomGenerator";
import {
  CalendarContentContainer,
  CalendarContentContainerFilerAndSelect,
  SelectStyled,
} from "./AppointmentPageContent.styled";
import { USER_ROLES } from "constants/userRoles";
import { MyAppointmentsConstants } from "constants/MyAppointmentsConstants";
import { useTranslation } from "react-i18next";
import { LabelContainer } from "components/Modals/ClientServiceModals/Modal.styled";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import CustomMultipleSelectField from "components/InputFields/CustomFields/CustomMultipleSelectField";

const AppointmentPageContent = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    statusActive: {},
    statusDone: {},
    statusCanceled: {},
    serviceName: {},
  });
  const [appliedFilters, setAppliedFilters] = useState({});
  const [appointments, setAppointments] = useState(null);
  const user = useSelector(selectCurrentUser);
  const roles = useSelector(selectCurrentRoles);
  const selectOptions = [
    MyAppointmentsConstants.UserAppointments.name,
    MyAppointmentsConstants.WishList.name,
  ];
  const [selectAppointmentsType, setAppointmentsType] = useState(
    selectOptions[0]
  );

  roles?.includes(USER_ROLES.CLIENT_EMPLOYEE.name)
    ? selectOptions.push(MyAppointmentsConstants.ClientEmployee.name)
    : null;

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  const { data: userAppointments, isLoading: userLoading } =
    useGetPaginatedAppointmentsForUserQuery({
      id: user?.id,
      pageSize: 5000,
      filters: {
        statuses: [
          appliedFilters?.statusActive?.id,
          appliedFilters?.statusDone?.id,
          appliedFilters?.statusCanceled?.id,
        ].filter((status) => status !== null && status !== undefined),
        serviceName: appliedFilters?.serviceName,
      },
    });

  const { data: wishListAppointments, isLoading: wishListLoading } =
    useGetUserWishListQuery({
      userId: user?.id,
    });

  const {
    data: ClientEmployeeAppointments,
    isLoading: clientEmployeeAppointmentsLoading,
  } = useGetAppointmentsForClientEmployeeQuery({
    userId: user?.id,
    pageSize: 5000,
    filters: {
      statuses: [
        appliedFilters?.statusActive?.id,
        appliedFilters?.statusDone?.id,
        appliedFilters?.statusCanceled?.id,
      ].filter((status) => status !== null && status !== undefined),
      serviceName: appliedFilters?.serviceName,
    },
  });

  useEffect(() => {
    if (
      selectAppointmentsType === MyAppointmentsConstants.UserAppointments.name
    ) {
      setAppointments(userAppointments?.data?.appointments || []);
    } else if (
      selectAppointmentsType === MyAppointmentsConstants.WishList.name
    ) {
      setAppointments(wishListAppointments?.data?.userAppointmentWishes || []);
    } else if (
      selectAppointmentsType === MyAppointmentsConstants.ClientEmployee.name
    ) {
      setAppointments(ClientEmployeeAppointments?.data?.appointments || []);
    }
  }, [
    selectAppointmentsType,
    userAppointments,
    wishListAppointments,
    ClientEmployeeAppointments,
    userLoading,
    wishListLoading,
    clientEmployeeAppointmentsLoading,
  ]);

  const handleStatusChange = (selectedIds) => {
    const updatedStatuses = selectedIds
      .map((id) => {
        const status = appointmentStatuses.find((status) => status.id === id);
        return status
          ? {
              id: status.id,
              name: t(status.name),
              valueToRemove: getValueToRemove(status.id),
            }
          : null;
      })
      .filter(Boolean);

    setFilters((prevState) => {
      const updatedFilters = { ...prevState };
      delete updatedFilters.statusActive;
      delete updatedFilters.statusDone;
      delete updatedFilters.statusCanceled;

      updatedStatuses.forEach((status) => {
        if (status.id === 0) {
          updatedFilters.statusActive = status;
        } else if (status.id === 2) {
          updatedFilters.statusDone = status;
        } else if (status.id === 1) {
          updatedFilters.statusCanceled = status;
        }
      });
      return updatedFilters;
    });

    saveFilters();
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"common.labelClientServiceName"}
          placeholder={"appointments.filters.inputClientServiceName"}
          onChange={(serviceName) =>
            setFilters((prevState) => ({
              ...prevState,
              serviceName: {
                id: serviceName,
                name: serviceName,
                valueToRemove: filterValueToRemove.SERVICE_NAME,
              },
            }))
          }
          value={filters?.serviceName?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <LabelContainer>
          <CustomFieldLabel label={t("common.status")} />
          <CustomMultipleSelectField
            fieldNames="statusNames"
            fieldIds="statusIds"
            name="statusIds"
            items={appointmentStatuses.slice(0, 3).map((status) => ({
              id: status.id,
              name: t(status.name),
              primaryId: status.id,
              isActive: true,
            }))}
            formik={{
              values: {
                statusNames: [
                  filters.statusActive?.name || "",
                  filters.statusDone?.name || "",
                  filters.statusCanceled?.name || "",
                ].filter((name) => name !== ""),
                statusIds: [
                  filters.statusActive?.id || null,
                  filters.statusDone?.id || null,
                  filters.statusCanceled?.id || null,
                ].filter((id) => id !== null),
              },
              setFieldValue: (field, value) => {
                if (field === "statusIds") {
                  handleStatusChange(value);
                }
              },
            }}
          />
        </LabelContainer>
      </InputFieldContainer>,
    ],
    [filters, t]
  );

  useEffect(() => {
    const updatedFilters = { ...filters };
    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key]?.name) {
        updatedFilters[key].name = t(
          appointmentStatuses[updatedFilters[key].id]?.name
        );
      }
    });

    setFilters(updatedFilters);
    setAppliedFilters(updatedFilters);
  }, [t]);

  const handleTypeChange = (e) => {
    setAppointmentsType(e.target.value);
  };

  return (
    <CalendarContentContainer>
      <CalendarContentContainerFilerAndSelect>
        <FilterContainer
          filterComponents={filterComponents}
          appliedFilters={appliedFilters}
          filters={filters}
          isFilterButtonShown={true}
          removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
          saveFilters={saveFilters}
        />
        <SelectStyled
          value={selectAppointmentsType}
          onChange={handleTypeChange}
        >
          {selectOptions.map((option) => {
            const label = Object.values(MyAppointmentsConstants).find(
              (constant) => constant.name === option
            )?.label;

            return (
              <option key={option} value={option}>
                {t(label)}
              </option>
            );
          })}
        </SelectStyled>
      </CalendarContentContainerFilerAndSelect>
      <MyCalendar appointments={appointments} isUser={true} />
    </CalendarContentContainer>
  );
};

export default AppointmentPageContent;
