import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

const notificationTag = "notifications";

const userTag = "user";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myUser: builder.query({
      query: () => ({
        url: "api/users/me",
      }),
      providesTags: [userTag],
    }),

    notifications: builder.query({
      query: () => ({
        url: "api/user/me/notifications",
      }),
      providesTags: [notificationTag],
    }),
    seeNotification: builder.mutation({
      query: (notificationId) => ({
        url: `api/user/me/notifications/${notificationId}`,
        method: "PATCH",
      }),
      invalidatesTags: [notificationTag],
    }),

    readNotification: builder.mutation({
      query: (userId, notificationId) => ({
        url: apiEndpoints.users.readNotification(userId, notificationId),
        method: "PATCH",
      }),
      invalidatesTags: [notificationTag],
    }),

    getNotifications: builder.query({
      query: (recipientId) => ({
        url: apiEndpoints.users.getUserNotifications(recipientId),
      }),
      providesTags: [notificationTag],
    }),

    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: `/api/users/${data?.userId}`,
        method: "PUT",
        body: data?.userInfo,
      }),
      invalidatesTags: [userTag],
    }),
    changeUserStatus: builder.mutation({
      query: (id) => ({
        url: apiEndpoints.users.changeStatus.replace("{id}", id),
        method: "PATCH",
      }),
      invalidatesTags: [userTag],
    }),
    getUser: builder.query({
      query: (id) => ({
        url: apiEndpoints.users.getUser.replace("{id}", id),
      }),
      providesTags: [userTag],
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: apiEndpoints.users.getAll,
      }),
      providesTags: [userTag],
    }),
    getPaginatedUsers: builder.query({
      query: ({ pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.users.getPaginated
          .replace("{pageNumber}", pageNumber)
          .replace("{pageSize}", pageSize),
      }),
      providesTags: [userTag],
    }),
    getPaginatedFilteredUsers: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, filters = {} }) => {
        const url = apiEndpoints.users.getPaginated(
          pageNumber,
          pageSize,
          filters
        );
        return { url };
      },
      providesTags: [userTag],
    }),
    updateUser: builder.mutation({
      query: ({ userId, userData }) => ({
        url: apiEndpoints.users.update(userId),
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: [userTag],
    }),
  }),
});

export const {
  useReadNotificationMutation,
  useGetNotificationsQuery,
  useMyUserQuery,
  useUpdateUserInfoMutation,
  useNotificationsQuery,
  useSeeNotificationMutation,
  useChangeUserStatusMutation,
  useGetUserQuery,
  useGetAllUsersQuery,
  useGetPaginatedUsersQuery,
  useGetPaginatedFilteredUsersQuery,
  useUpdateUserMutation,
} = usersApiSlice;
